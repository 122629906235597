import React, { Component } from 'react';
import '../Branding/Common.css';
import 'font-awesome/css/font-awesome.min.css';
//import WayneRancourt from '../Branding/WayneRancourt.jpg'
import { UserAgentApplication } from 'msal';
import { config } from '../AD_config/Config.js';
import { normalizeError, getSingleProfile, getMSteamsGraphApiSingleUser } from '../AD_config/MSUtils.js';
import Swal from 'sweetalert2';
import { getApiToken } from '../AD_config/MSUtils.js';

class ManageHR extends Component {

	constructor(props) {
		super(props);
		this.getHRdetails = this.getHRdetails.bind(this);
		this.handleCheckAll = this.handleCheckAll.bind(this);
		this.singleUser = this.singleUser.bind(this);
		this.singleUsergraph = this.singleUsergraph.bind(this);
		this.submit = this.submit.bind(this);
		this._handleKeyDown = this._handleKeyDown.bind(this);
		this.cancel = this.cancel.bind(this);
		this.handleRemoveRow = this.handleRemoveRow.bind(this);

		this.state = {
			pSave: '',
			rows: [],
			hrows: [],
			prows: [],
			namerows: [],
			checkedBoxes: [],
			adEmail: '',
			adRoom: '',
			adDivision: '',
			postRes: '',
			hrHide: false,
			hrAuthorization: true,
			hrEmail: '',
			checkedall: false,
			hrerror: [],
			btnSaveStatus: true,
			btnAddDelStatus: false,
			errors: {}
		};
	}

	cancel(){
		this.getHRdetails();
		this.setState({
			btnSaveStatus: true,
			btnAddDelStatus: false
		})
	}

	async getHRdetails() {

		const apiToken = await getApiToken();
		if(apiToken){
			this.setState({
				errors: {}
			})
			var stremail = this.props.Email;
			if(this.props.Email == null || this.props.Email == '')
				stremail = this.props.userPrincipalName;
			const taskOptions = {
				method: 'GET',
				headers: { 
							'Content-Type': 'application/json',
							'Authorization': 'Bearer ' + apiToken, 
						}
			};

			await fetch(this.props.apiURL + 'Daily/getHradminDetails/' + stremail + "/", taskOptions)
			.then(function(res) {
				return res.json();
			}).then((json)=> {
				if(json.hradminDetails.length > 0){
					this.setState({
						hrHide: true,
						hrAuthorization: false,
						rows: json.hradminDetails,
						hrows: json.hradminDetails,
						prows: json.hradminDetails,
						namerows: json.hradminDetails,
					})
				}
			});
		}
	  }

	async submit() {

		try{
			const apiToken = await getApiToken();
			if(apiToken){
					var stremail = this.props.Email;
					if(this.props.Email == null || this.props.Email == '')
						stremail = this.props.userPrincipalName;
					
					if(this.validate()){
						let nrows = [...this.state.rows]	
						if(nrows.length > 0){
							for(var i=0; i < nrows.length; i++){
								if(this.state.rows[i]){
									this.state.errors[i] = "";
									if(!this.state.namerows[i].hrname){
										Swal.fire({
											icon: 'error',
											title: 'Please Enter email and press Tab to locate HR Staff!'
										})
										return;
									}
								}
							}
						}
						if(nrows.length > 0){
							for(var i=0; i < nrows.length; i++){
								var hrdivision = "";
								var hrroom = "";

								if(this.state.rows[i].hremail != null && this.state.rows[i].hremail != ""){
									
									if(this.state.namerows[i].hrname != null && this.state.namerows[i].hrname != ""){
										if(this.state.hrows[i].hrdivision != null && this.state.hrows[i].hrdivision !=""){
											hrdivision = this.state.hrows[i].hrdivision;
										}

										if(this.state.prows[i].hrroom != null && this.state.prows[i].hrroom !=""){
											hrroom = this.state.prows[i].hrroom;
										}
										
								const taskOptions = {
									method: 'PUT',
									headers: { 
												'Content-Type': 'application/json',
												'Authorization': 'Bearer ' + apiToken 
											 },
									body: JSON.stringify({ email: this.state.rows[i].hremail.toLowerCase(),
															hradminname: this.state.namerows[i].hrname,
															divisionname: hrdivision,
															roomname: hrroom,
															updatedby: stremail,
															count: 0
								})
								};
								await fetch(this.props.apiURL + 'Daily/Managehr', taskOptions)
										.then(function(response) {
											return response.json();
											
										}).then((data)=> {
											this.setState({
												postRes: data.hradminDetails[0].res,
											})
										});	
									}
							}
						}

							if(this.state.postRes == 1){
								Swal.fire({
									icon: 'success',
									title: 'Data saved successfully'
								})
								this.getHRdetails();
							}

							this.setState({
								btnSaveStatus: true,
								btnAddDelStatus: false
							})
						}else{
							Swal.fire({
								icon: 'error',
								title: 'Please enter HR details!'
							})
							return;
						}	
				}
		}
		}catch(err){
			throw err;
		}
	}
		  
	_handleKeyDown = idx => e => {
		if (e.key === 'Enter' || e.key === 'Tab') {
			var cvalue = 0;
			this.state.rows.map(function(item, i){
				if(i != idx){
					if(cvalue == 0){
						if(e.target.value.toLowerCase() == item["hremail"]){
							Swal.fire({
								icon: 'error',
								title: 'Duplicate value not allowed!'
							  })
							e.target.value = "";
							cvalue = 1;
							return;
						}
					}
				}
			  })
			if(cvalue == 0) {
				if(this.props.graphToken != "browser")
					this.singleUsergraph(e.target.value, idx);
				else
					this.singleUser(e.target.value, idx);
			}
		}
	  }

	handleChangeemail = idx => e => {
		const { name, value } = e.target;
		const rows = [...this.state.rows];
		rows[idx] = {
		  [name]: value.toLowerCase()
		};
		this.setState({
		  rows
		});
	  };

	  handleChangeemailspace = (idx) => {
		const rows = [...this.state.rows];

		rows[idx] = {
			["hremail"]: ""
			};
		this.setState({
		  rows
		});
	  };

	  handleChangedivision = (idx, val) => {
		const hrows = [...this.state.hrows];
		if(val == null || val == ""){
			hrows[idx] = {
			["hrdivision"]: ""
			};
		}else{
			hrows[idx] = {
				["hrdivision"]: val
			};
		}
		this.setState({
		  hrows
		});
	  };

	  handleChangeroom = (idx, val) => {
		const prows = [...this.state.prows];
		if(val == null || val == ""){
			prows[idx] = {
			["hrroom"]: ""
			};
		}else{
			prows[idx] = {
				["hrroom"]: val
			};
		}
		this.setState({
		  prows
		});
	  };

	  handleChangename = (idx, val) => {
		const namerows = [...this.state.namerows];
		if(val == null || val == ""){
			namerows[idx] = {
			["hrname"]: ""
			};
		}else{
			namerows[idx] = {
				["hrname"]: val
			};
		}
		this.setState({
			namerows
		});
	  };
	  

	handleAddRow = (idx) => {
		const item = {
		  chkRow: ""
		};
		this.setState({
		  rows: [...this.state.rows, item],
		  hrows: [...this.state.hrows, item],
		  prows: [...this.state.prows, item],
		  namerows: [...this.state.namerows, item],
		  btnSaveStatus: false,
		  btnAddDelStatus: true
		});
	  }

	  async handleRemoveRow() {
		try{
			const apiToken = await getApiToken();
			if(apiToken){
			if(this.state.checkedall === true || this.state.checked === true){
				Swal.fire({
					title: 'Are you sure?',
					text: "You won't be able to revert this!",
					icon: 'warning',
					showCancelButton: true,
					confirmButtonColor: '#3085d6',
					cancelButtonColor: '#d33',
					confirmButtonText: 'Yes, delete it!'
				}).then((result) => {
					if (result.isConfirmed) {
						if(this.state.checkedall == false) {
							for(var i=0; i < this.state.rows.length; i++){

								if(this.refs["check_row" + i]){
								if(this.refs["check_row" + i].checked === true){
									if(this.refs["HRAdmin_ID" + i].value){

									var hradminid = this.refs["HRAdmin_ID" + i].value;
									const taskOptions = {
										method: 'PUT',
										headers: { 
													'Content-Type': 'application/json',
													'Authorization': 'Bearer ' + apiToken 
												 },
										body: JSON.stringify({ hradminid: hradminid
									})
									};
									fetch(this.props.apiURL + 'Daily/deleteHRAdminById', taskOptions)
											.then(function(response) {
												return response.json();
												
											}).then((data)=> {
												this.getHRdetails();
											});
								}
							}
						}
								this.refs["check_row" + i].checked = false;
							}
						}else {

							for(var i=0; i < this.state.rows.length; i++){
								if(this.refs["check_row" + i]){
								if(this.refs["check_row" + i].checked === true){
									if(this.refs["HRAdmin_ID" + i].value){
									var hradminid = this.refs["HRAdmin_ID" + i].value;
									const taskOptions = {
										method: 'PUT',
										headers: { 
													'Content-Type': 'application/json',
													'Authorization': 'Bearer ' + apiToken  
												 },
										body: JSON.stringify({ hradminid: hradminid
									})
									};
									fetch(this.props.apiURL + 'Daily/deleteHRAdminById', taskOptions)
											.then(function(response) {
												return response.json();
												
											}).then((data)=> {
												this.getHRdetails();
											});
								}
							}
						}
								this.refs["check_row" + i].checked = false;
							}
						}
						this.setState({
							rows: [],
							hrows: [],
							checkedall : false,
							checked : false
						})

						Swal.fire(
							'Deleted!',
							'success'
						)			
					}
				})
				}
		}
		}catch(err){
			throw err;
		}

	  }

	  handleCheckboxChange = idx => e => {
		this.state.checkedall = false

		if (e.target.checked) {
			this.state.checked = true;
			e.target.checked = true;
		}else{
			this.state.checked = false;
		}
	  }

	  handleCheckAll= e => {
		 
		let nrows = [...this.state.rows]	
		this.setState({
			 checkedall: false,	
		})

		for(var i=0; i < nrows.length; i++){
			this.refs["check_row" + i].checked = false;
		  }

		if (e.target.checked) {

		  this.setState ({
			   checkedall: true	
		  })

		  for(var i=0; i < nrows.length; i++){
			this.refs["check_row" + i].checked = true;
		  }
		}
	  }

	  async singleUsergraph(hremail, idx) {
		try {
		 
			const suser = await getMSteamsGraphApiSingleUser(this.props.graphToken, hremail);
		  	//const suser = await getSingleProfile(userAgentApplication, config.scopes, hremail);
		  
		  if(suser){
			  if(suser.value[0]){
				this.setState({
				isAuthenticated: true,
				suser: {
					sdisplayName: suser.value[0].displayName,
					semail: suser.value[0].mail || suser.value[0].userPrincipalName,
					sid: suser.value[0].id,
					sattrRoom: suser.value[0].onPremisesExtensionAttributes.extensionAttribute4,
					sattrDivision: suser.value[0].onPremisesExtensionAttributes.extensionAttribute5,
				},
				error: null
				});
				
				this.refs["hrdivision" + idx].value = suser.value[0].onPremisesExtensionAttributes.extensionAttribute5;
				this.refs["hrroom" + idx].value = suser.value[0].onPremisesExtensionAttributes.extensionAttribute4;
				this.refs["hrname" + idx].value = suser.value[0].displayName;
				
				this.handleChangedivision(idx, suser.value[0].onPremisesExtensionAttributes.extensionAttribute5);
				this.handleChangeroom(idx, suser.value[0].onPremisesExtensionAttributes.extensionAttribute4);
				this.handleChangename(idx, suser.value[0].displayName);
		}else{
			Swal.fire({
				icon: 'error',
				title: 'Email not found, please validate and try again!'
			  })
			  
			 this.handleChangeemailspace(idx); 
			this.refs["hremail" + idx].value = "";
			this.refs["hrdivision" + idx].value = "";
			this.refs["hrroom" + idx].value = "";
			this.refs["hrname" + idx].value = "";
			return;
		}
		}
		}
		catch (err) {
		  this.setState({
		  isAuthenticated: false,
		  suser: {},
		  error: normalizeError(err)
		  });
		}
	  }



	  async singleUser(hremail, idx) {
		try {
		  let userAgentApplication = new UserAgentApplication({
			auth: {
			  clientId: config.clientId,
			  redirectUri: window.location.origin, 
			  authority: config.authority,
			  navigateToLoginRequestUrl: false,
			},
			cache: {
			  cacheLocation: "localStorage",
			  storeAuthStateInCookie: true
			}
			});

		  const suser = await getSingleProfile(userAgentApplication, config.scopes, hremail);
		  
		  if(suser){
			  if(suser.value[0]){
				this.setState({
				isAuthenticated: true,
				suser: {
					sdisplayName: suser.value[0].displayName,
					semail: suser.value[0].mail || suser.value[0].userPrincipalName,
					sid: suser.value[0].id,
					sattrRoom: suser.value[0].onPremisesExtensionAttributes.extensionAttribute4,
					sattrDivision: suser.value[0].onPremisesExtensionAttributes.extensionAttribute5,
				},
				error: null
				});
				
				this.refs["hrdivision" + idx].value = suser.value[0].onPremisesExtensionAttributes.extensionAttribute5;
				this.refs["hrroom" + idx].value = suser.value[0].onPremisesExtensionAttributes.extensionAttribute4;
				this.refs["hrname" + idx].value = suser.value[0].displayName;
				
				this.handleChangedivision(idx, suser.value[0].onPremisesExtensionAttributes.extensionAttribute5);
				this.handleChangeroom(idx, suser.value[0].onPremisesExtensionAttributes.extensionAttribute4);
				this.handleChangename(idx, suser.value[0].displayName);
		}else{
			Swal.fire({
				icon: 'error',
				title: 'Email not found, please validate and try again!'
			  })
			  
			 this.handleChangeemailspace(idx); 
			this.refs["hremail" + idx].value = "";
			this.refs["hrdivision" + idx].value = "";
			this.refs["hrroom" + idx].value = "";
			this.refs["hrname" + idx].value = "";
			return;
		}
		}
		}
		catch (err) {
		  this.setState({
		  isAuthenticated: false,
		  suser: {},
		  error: normalizeError(err)
		  });
		}
	  }

	  validate(){
		let errors = {};
		let isValid = true;

		let nrows = [...this.state.rows]	
				if(nrows.length > 0){
					for(var i=0; i < nrows.length; i++){
						if(this.state.rows[i]){
							if(!this.state.rows[i].hremail){
								isValid = false;
								errors[i] = "Please enter your email Address.";
							}

							if(this.state.rows[i].hremail){
								var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
							if (!pattern.test(this.state.rows[i].hremail)) {
								isValid = false;
								errors[i] = "Please enter valid email address.";
							}
							}
						}
					}
				}
	
		this.setState({
		  errors: errors
		});
	
		return isValid;
	}

	componentDidMount() {
		this.getHRdetails();		
	}

  render() {
	return  (
		<div className="manage-hr-wrapper">
		<div className="header">
		  <h4>Manage HR</h4>
		</div>
		<div className={this.state.hrHide ? 'hrhidden' : 'authorization-wrapper'}>
		<p className="phrhidden"><i class="fa fa-cog" aria-hidden="true"></i>
You need HR Admin authorization</p>
			
		</div>
		<div className={this.state.hrAuthorization ? 'hrhidden' : ''}>
		<div className="container">
			<div className="middle-section manage-teams-wrapper attendance-planner-wrapper"> 
				<div className="d-table-wrapper">
						 <div className="d-table-body">
						<div className="table-buttons-wrapper"> 
							<button onClick={this.handleAddRow} className="btn btn-default pull-left" disabled={this.state.btnAddDelStatus}><i className="fa fa-plus" aria-hidden="true" ></i>&nbsp;Add</button>
							<button onClick={this.handleRemoveRow} className="btn btn-default" disabled={this.state.btnAddDelStatus}><i className="fa fa-trash" aria-hidden="true" ></i>&nbsp;Delete</button>
						 </div>
						  <div className="table-responsive">
								 <table>
						 <thead>
								  <tr>
								  <th className="hrhidden"> # </th>
									<th width="30"> 
										<input 
							  type="checkbox" 
							  name="chkAll"
							onChange={this.handleCheckAll.bind(this)}
							checked={this.state.checkedall}  
						/>
									</th>
									<th width="25%">Email</th>
									<th width="25%">Name</th>
									<th width="25%">Division</th>
									<th width="25%">Room or Area</th>
									</tr>
								  </thead>
								  <tbody>
								  {this.state.rows.map((item, idx) => (
								  <tr id="addr0" key={idx}>
								  <td className='hrhidden'>{idx}</td>
					  <td>
						<input 
						  type="checkbox" 
						  name="chkRow"
						  onChange={this.handleCheckboxChange(idx)}
						  ref={"check_row" + idx}
						  id={idx}
						  />
					  </td>
											<td>
												<div className="user-wrapper">
													<div className="user-details">
														

														 <input
						  type="text"
						  name="hremail"
						  ref={"hremail" + idx}
						  value={this.state.rows[idx].hremail}
						  onChange={this.handleChangeemail(idx)}
						  onKeyDown={this._handleKeyDown(idx)}
						  className="form-control"
						  disabled = {this.state.btnSaveStatus}
						/>
						<div className="text-danger">{this.state.errors[idx]}</div>

													 </div>
												
												</div>
											</td>
											<td>
												{/* <label for="hrname">{this.state.namerows[idx].hrname}</label> */}

												<input
						  type="text"
						  name="hrname"
						  ref={"hrname" + idx}
						  value={this.state.namerows[idx].hrname}
						  className="form-control inputtextbox"
						  readOnly = {true}
						/>
											</td>
					<td>
						{/* <label for="hrdivision" ref={"hrdivision" + idx}>{this.state.hrows[idx].hrdivision}</label> */}
						<input
						  type="text"
						  name="hrdivision"
						  ref={"hrdivision" + idx}
						  value={this.state.hrows[idx].hrdivision}
						  className="form-control inputtextbox"
						  readOnly = {true}
						/>
					</td>
					<td>
					{/* <label for="hrroom" ref={"hrroom" + idx}>{this.state.prows[idx].hrroom}</label> */}
						<input
						  type="text"
						  name="hrroom"
						  ref={"hrroom" + idx}
						  value={this.state.prows[idx].hrroom}
						  className="form-control inputtextbox"
						  readOnly = {true}
						/>

<input
							 type="hidden"
							 name="HRAdminID" 
							 value={this.state.rows[idx].HRAdminID}
							 ref={"HRAdmin_ID" + idx}
							 //onChange={this.handleChangeTask(idx)}
							 className="form-control"
						   />
					</td>
										 </tr>
										 ))}
									  </tbody>
								</table>
								</div>
					</div>
					
					<div className="d-table-bottom text-right"> 
					<span className="info-message fl"><i class="fa fa-info-circle" aria-hidden="true"></i>
					Enter email and press Tab to locate HR Staff</span>	
							<button
								className="primary-btn btn" 
								value="Save"
								onClick={this.submit}
								disabled = {this.state.btnSaveStatus}
								> 
								Save
							</button>
							<button
						  className="primary-btn btn" 
						  value="Cancel"
						  disabled={this.state.btnSaveStatus}
						onClick={this.cancel}
						  > 
						  Cancel
					  </button>
					</div>
					<div className="psave"><p> {this.state.pSave}</p></div>
				</div>
			
			</div>
	
	
	</div>
	</div>
	  </div>
);
}
}

export default ManageHR
