var graph = require('@microsoft/microsoft-graph-client');


function getAuthenticatedClient(accessToken) {
    const client = graph.Client.init({
        authProvider: (done) => {
            done(null, accessToken);
        }
    });

    return client;
}

export async function getUserDetails(accessToken) {
    const client = getAuthenticatedClient(accessToken);

    const user = await client
        .api('/me')//?&$select=id,displayname,mail,officeLocation,onPremisesExtensionAttributes
        .select('id,displayName,mail,officeLocation,userPrincipalName,onPremisesExtensionAttributes')
        .get();

    return user;
}

export async function getSingleUser(accessToken, hremail) {
    const client = getAuthenticatedClient(accessToken);

    const singleuser = await client
        .api("/users")//?&$select=id,displayname,mail,officeLocation,onPremisesExtensionAttributes
        .filter("startswith(userPrincipalName," + "'" + hremail + "'" + ")")
        .select('id,displayName,mail,officeLocation,userPrincipalName,onPremisesExtensionAttributes')
        .get();

    return singleuser;
}

export async function getMsTeamsGraph(accessToken) {
    debugger;
    const client = getAuthenticatedClient(accessToken);

    const user = await client
        .api('/me')
        .select('id,displayName,mail,officeLocation,userPrincipalName,onPremisesExtensionAttributes')
        .get();

    return user;
}

export async function getMsTeamsGraphSingleUser(accessToken, hremail) {
    const client = getAuthenticatedClient(accessToken);

    const singleuser = await client
        .api("/users")
        .filter("startswith(userPrincipalName," + "'" + hremail + "'" + ")")
        .select('id,displayName,mail,officeLocation,userPrincipalName,onPremisesExtensionAttributes')
        .get();

    return singleuser;
}

//https://graph.microsoft.com/v1.0/users?$filter=startswith(userPrincipalName,'HSepahani@bos.lacounty.gov')&$select=id,displayname,mail,officeLocation,onPremisesExtensionAttributes