/*Main Component*/
import React, { Component } from 'react';
import DailyCheck from '../Tabs/DailyCheckIn';
import Supervisors from '../Tabs/Supervisors';
import AttendancePlanner from '../Tabs/AttendancePlanner';
import ManageSupervisor from '../Tabs/ManageSupervisor';
import ManageHR from '../Tabs/ManageHR';
import Analytics from '../Tabs/Analytics';
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
//import { Paper } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { Link, Route, BrowserRouter, Switch, Redirect } from "react-router-dom";
//import ReactCSSTransitionGroup from "react-addons-css-transition-group";
//import { logDOM } from '@testing-library/dom';
import logo from '../Branding/logo.png'
import { UserAgentApplication } from 'msal';
import { config } from '../AD_config/Config.js';
import { normalizeError, getUserProfile, getSingleProfile } from '../AD_config/MSUtils.js';
import * as microsoftTeams from "@microsoft/teams-js";
import '../Branding/Common.css';
import { getHradminRole } from '../AD_config/MSUtils.js';


function TabContainer({ children, dir }) {
  return (
    <Typography component="div" dir={dir} style={{ padding: 8 * 3 }}>
      {children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
  dir: PropTypes.string.isRequired
};

const styles = theme => ({
  root: {
    backgroundColor: theme.palette.background.paper
    //width: 500
  }
});

class TeleTrack extends Component {

  constructor() {
    super();
  this.state = {
    value: 1,
    isOpen: false,
    error: null,
    isAuthenticated: false,
    user: {},
    suser: {},
    apiURL: config.apiURL,
    chkUser: [],
    context: {},
    hrAdmin : 0,
    supAdmin: 0,
    analyticsAdmin: 0
  };

  // Initialize the MSAL application object
  this.userAgentApplication=new UserAgentApplication({
    auth: {
      clientId: config.clientId,
      redirectUri: window.location.origin,
      authority: config.authority,
      navigateToLoginRequestUrl: false
     },
    cache: {
      cacheLocation: "localStorage",
      storeAuthStateInCookie: true
    }
  })

    this.fnDailyCheck = this.fnDailyCheck.bind(this)
    this.fnSupervisors = this.fnSupervisors.bind(this)
    this.fnAttendancePlanner = this.fnAttendancePlanner.bind(this)
    this.fnManageSupervisor = this.fnManageSupervisor.bind(this)
    this.fnManageHR = this.fnManageHR.bind(this)
    this.fnAnalytics = this.fnAnalytics.bind(this)
    this.singleUser = this.singleUser.bind(this);
    this.login = this.login.bind(this);
  }

  toggle() {
		this.setState({
		  isOpen: !this.state.isOpen
		});
	  }

	async login() {
      try {
        let account = this.userAgentApplication.getAccount();
        if (!account) { //!account actual
            await this.userAgentApplication.loginPopup(
            {
              scopes: config.scopes.logout,
              prompt: "select_account"//"none"//"select_account"
            });
        }
        this.getProfile();
      }
        catch (err) {
          console.log("Error acquiring:\n" + err);
      }
	}

    async getProfile(){
      try{
          const user = await getUserProfile(this.userAgentApplication, config.scopes);
          this.setState({
          isAuthenticated: true,
          user: {
            displayName: user.displayName,
            email: user.mail,
            userPrincipalName: user.userPrincipalName,
            id: user.id,
            attrRoom: user.onPremisesExtensionAttributes.extensionAttribute4,
            attrDivision: user.onPremisesExtensionAttributes.extensionAttribute5,
            //attrroom: user.onPremisesExtensionAttributes.extensionAttribute5
          },
          hrAdmin: user.mail ? await getHradminRole(user.mail, "getHradminRole") : await getHradminRole(user.userPrincipalName, "getHradminRole"),
          supAdmin: user.mail ? await getHradminRole(user.mail, "getSupervisorAdmin") : await getHradminRole(user.userPrincipalName, "getSupervisorAdmin"),
          analyticsAdmin: user.mail ? await getHradminRole(user.mail, "getAnalyticsadminRole") : await getHradminRole(user.userPrincipalName, "getAnalyticsadminRole"),
          error: null,
          chkUser : "1",
          });
        }catch (err) {
          this.setState({
          isAuthenticated: false,
          user: {},
          error: normalizeError(err)
          });
        }
    }

    async singleUser() {
      try {
        let userAgentApplication = new UserAgentApplication({
          auth: {
            clientId: config.clientId,
            redirectUri: window.location.origin, 
            authority: config.authority,
            navigateToLoginRequestUrl: false,
          },
          cache: {
            cacheLocation: "localStorage",
            storeAuthStateInCookie: true
          }
          });

        const suser = await getSingleProfile(userAgentApplication, config.scopes, 'HSepahani@bos.lacounty.gov');
        
        this.setState({
        isAuthenticated: true,
        suser: {
          sdisplayName: suser.value[0].displayName,
          semail: suser.value[0].mail || suser.value[0].userPrincipalName,
          sid: suser.value[0].id,
          sattrRoom: suser.value[0].onPremisesExtensionAttributes.extensionAttribute4,
          sattrDivision: suser.value[0].onPremisesExtensionAttributes.extensionAttribute5,
        },
        error: null
        });
      }
      catch (err) {
        this.setState({
        isAuthenticated: false,
        suser: {},
        error: normalizeError(err)
        });
      }
    }

  handleChange = (event, value) => {
    this.setState({ value });
  };

  handleChangeIndex = index => {
    this.setState({ value: index });
  };

 
  fnDailyCheck() {

    return this.state.chkUser.length ? (
      <DailyCheck 
        apiURL= {this.state.apiURL}
        displayName = {this.state.user.displayName} 
        attrRoom = {this.state.user.attrRoom}
        attrDivision = {this.state.user.attrDivision}
        Email = {this.state.user.email}
        userPrincipalName = {this.state.user.userPrincipalName}
     />
      
  ) : (
    'Loading...'
  );

  }

  fnSupervisors() {
      return this.state.chkUser.length ? (
        <Supervisors 
          apiURL= {this.state.apiURL}
          displayName = {this.state.user.displayName} 
          attrRoom = {this.state.user.attrRoom}
          attrDivision = {this.state.user.attrDivision}
          Email = {this.state.user.email}
          userPrincipalName = {this.state.user.userPrincipalName}
      />
        
    ) : (
      'Loading...'
    );
  }
  
  fnAttendancePlanner() {
      return this.state.chkUser.length ? (
        <AttendancePlanner 
          apiURL= {this.state.apiURL}
          displayName = {this.state.user.displayName} 
          Email = {this.state.user.email}
          userPrincipalName = {this.state.user.userPrincipalName}
          graphToken = "browser"
      />
        
    ) : (
      'Loading...'
    );
  }
  
  fnManageSupervisor() {
      return this.state.chkUser.length ? (
        <ManageSupervisor 
          apiURL= {this.state.apiURL}
          displayName = {this.state.user.displayName} 
          Email = {this.state.user.email}
          userPrincipalName = {this.state.user.userPrincipalName}
          graphToken = "browser"
      />
        
    ) : (
      'Loading...'
    );
  }
  
  fnManageHR() {
      return this.state.chkUser.length ? (
        <ManageHR 
          apiURL= {this.state.apiURL}
          displayName = {this.state.user.displayName} 
          Email = {this.state.user.email}
          userPrincipalName = {this.state.user.userPrincipalName}
          graphToken = "browser"
      />
        
    ) : (
      'Loading...'
    );
  }

  fnAnalytics() {
    return this.state.chkUser.length ? (
      <Analytics 
        apiURL= {this.state.apiURL}
        displayName = {this.state.user.displayName} 
        Email = {this.state.user.email}
        userPrincipalName = {this.state.user.userPrincipalName}
        graphToken = "browser"
    />
      
  ) : (
    'Loading...'
  );
}

  componentDidMount(){
    //this.login();
    //let account=this.userAgentApplication.getAccount();
    //alert(account);
    //if(!account){
    this.login();
    //}else{
      //this.getProfile();
    //}
    /*microsoftTeams.getContext((context, error) => {
        this.setState({
          context: context
        });
      });
      */

      //let userName = Object.keys(this.state.context).length > 0 ? this.state.context['upn'] : "";
      //alert(userName);
  }

  render() {
 
    const { classes, theme } = this.props;
    return (
      <div>
        
      <BrowserRouter>
        <div className={classes.root}>
          <AppBar position="static" color="default">
            <Tabs
              value={this.state.value}
              onChange={this.handleChange}
              indicatorColor="primary"
              textColor="primary"
              variant="scrollable"
              scrollButtons="on"
            >
              <div className="logowrapper"><img src={logo} alt="Logo" /></div>
              <div>
                <Tab label="Daily Check-in" component={Link} to="/DailyCheck" wrapped />
              </div>
              <div className={this.state.supAdmin < 1 ? 'hrhidden' : ''}>
                <Tab label="Proxy Check-In" component={Link} to="/TeamSchedule" wrapped/>
              </div>
              <div className={this.state.supAdmin < 1 ? 'hrhidden' : ''}>
                <Tab label="Team Planned Attendance" component={Link} to="/Supervisor" wrapped/>
              </div>
              <div className={this.state.hrAdmin < 1 ? 'hrhidden' : ''}>
                <Tab label="Manage Supervisor" component={Link} to="/ManageSupervisor" wrapped/>
              </div>
              <div className={this.state.hrAdmin < 1 ? 'hrhidden' : ''}>
                <Tab label="Manage HR" component={Link} to="/ManageHR" wrapped />
              </div>
              <div className={this.state.analyticsAdmin < 1 ? 'hrhidden' : ''}>
                <Tab label="Analytics" component={Link} to="/Analytics" wrapped />
              </div>
            </Tabs>
          </AppBar>

          <Switch>
            <Route path="/DailyCheck" component={this.fnDailyCheck} />
            <Route exact path="/TeamSchedule" component={this.fnSupervisors} />
            <Route exact path="/Supervisor" component={this.fnAttendancePlanner} />
            <Route exact path="/ManageSupervisor" component={this.fnManageSupervisor} />
            <Route exact path="/ManageHR" component={this.fnManageHR} />
            <Route exact path="/Analytics" component={this.fnAnalytics} />
            <Route exact path="/">
                <Redirect to="DailyCheck" />
            </Route> 
          </Switch>
        </div>
      </BrowserRouter>
      </div>
    );
  }
}

/*
const PageShell = (Page, previous) => {
  return props => (
    <div className="page">
      <ReactCSSTransitionGroup
        transitionAppear={true}
        transitionAppearTimeout={600}
        transitionEnterTimeout={600}
        transitionLeaveTimeout={600}
        transitionName={props.match.path === "/one" ? "SlideIn" : "SlideOut"}
      >
        {console.log(props)}
        <Page {...props} />
      </ReactCSSTransitionGroup>
    </div>
  );
};
*/

export default withStyles(styles, { withTheme: true })(TeleTrack);


//export default TeleTrack