export const config = {
        clientId: 'e0dc339c-6f4f-46a0-88f4-7ef7a2509f73',//'c9aba048-e59c-4206-874b-e91c2d48a473', 
        authority: 'https://login.microsoftonline.com/7faea798-6ad0-4fc9-b068-fcbcaed341f6', 
        redirectUri:'https://app-teletrack.bos.lacounty.gov/', //'http://localhost:3000',//'https://app-teletrack.bos.lacounty.gov/', //'https://app-teletrack.azurewebsites.net/',//'http://localhost:3000', //'https://app-teletrack-dev.azurewebsites.net/'
        scopes: [
            'user.read',
            'user.read.all',
            'user.readbasic.all',
            'directory.accessasuser.all',
            'directory.read.all',
        ],    
        apiURL: 'https://api-teletrack.bos.lacounty.gov/api/',//'https://api-teletrack-dev.azurewebsites.net/api/',
        powerBi: 'https://app.powerbigov.us/links/bfX2hwZvRa?ctid=7faea798-6ad0-4fc9-b068-fcbcaed341f6'
    };