/*Main Component Teams*/
import React, { Component } from 'react';
import DailyCheck from '../Tabs/DailyCheckIn';
import Supervisors from '../Tabs/Supervisors';
import AttendancePlanner from '../Tabs/AttendancePlanner';
import ManageSupervisor from '../Tabs/ManageSupervisor';
import ManageHR from '../Tabs/ManageHR';
import Analytics from '../Tabs/Analytics';
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import { Link, Route, BrowserRouter, Switch, Redirect } from "react-router-dom";
import logo from '../Branding/logo.png'
import { config } from '../AD_config/Config.js';
import '../Branding/Common.css';
import { normalizeError, getMSteamsGraphApi, getHradminRole} from '../AD_config/MSUtils.js';
import * as microsoftTeams from "@microsoft/teams-js";

function TabContainer({ children, dir }) {
  return (
    <Typography component="div" dir={dir} style={{ padding: 8 * 3 }}>
      {children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
  dir: PropTypes.string.isRequired
};

const styles = theme => ({
  root: {
    backgroundColor: theme.palette.background.paper
    //width: 500
  }
});

class TeleTrackTeam extends Component {

  constructor() {
    super();
  this.state = {
    value: 1,
    isOpen: false,
    error: null,
    isAuthenticated: false,
    user: {},
    suser: {},
    apiURL: config.apiURL,
    chkUser: [],
    context: {},
    graphToken: null,
    hrAdmin : 0,
    supAdmin: 0,
    analyticsAdmin: 0
  };

  // Initialize the MSAL application object
    this.fnDailyCheck = this.fnDailyCheck.bind(this)
    this.fnSupervisors = this.fnSupervisors.bind(this)
    this.fnAttendancePlanner = this.fnAttendancePlanner.bind(this)
    this.fnManageSupervisor = this.fnManageSupervisor.bind(this)
    this.fnManageHR = this.fnManageHR.bind(this)
    this.fnAnalytics = this.fnAnalytics.bind(this)
  }

  toggle() {
		this.setState({
		  isOpen: !this.state.isOpen
		});
	  }

    async getProfileMSTeams(token){
      try{
          const user = await getMSteamsGraphApi(token);
          this.setState({
          isAuthenticated: true,
          user: {
            displayName: user.displayName,
            email: user.mail,
            userPrincipalName: user.userPrincipalName,
            id: user.id,
            attrRoom: user.onPremisesExtensionAttributes.extensionAttribute4,
            attrDivision: user.onPremisesExtensionAttributes.extensionAttribute5,
          },
          hrAdmin: user.mail ? await getHradminRole(user.mail, "getHradminRole") : await getHradminRole(user.userPrincipalName, "getHradminRole"),
          supAdmin: user.mail ? await getHradminRole(user.mail, "getSupervisorAdmin") : await getHradminRole(user.userPrincipalName, "getSupervisorAdmin"),
          analyticsAdmin: user.mail ? await getHradminRole(user.mail, "getAnalyticsadminRole") : await getHradminRole(user.userPrincipalName, "getAnalyticsadminRole"),
          error: null,
          chkUser : "1",
          });
        }catch (err) {
          this.setState({
          isAuthenticated: false,
          user: {},
          error: normalizeError(err)
          });
        }
    }

  handleChange = (event, value) => {
    this.setState({ value });
  };

  handleChangeIndex = index => {
    this.setState({ value: index });
  };

 
  fnDailyCheck() {
    return this.state.chkUser.length ? (
      <DailyCheck 
        apiURL= {this.state.apiURL}
        displayName = {this.state.user.displayName} 
        attrRoom = {this.state.user.attrRoom}
        attrDivision = {this.state.user.attrDivision}
        Email = {this.state.user.email}
        userPrincipalName = {this.state.user.userPrincipalName}
     />
      
  ) : (
    'Loading...'
  );

  }

  fnSupervisors() {
      return this.state.chkUser.length ? (
        <Supervisors 
          apiURL= {this.state.apiURL}
          displayName = {this.state.user.displayName} 
          attrRoom = {this.state.user.attrRoom}
          attrDivision = {this.state.user.attrDivision}
          Email = {this.state.user.email}
          userPrincipalName = {this.state.user.userPrincipalName}
      />
        
    ) : (
      'Loading...'
    );
  }
  
  fnAttendancePlanner() {
      return this.state.chkUser.length ? (
        <AttendancePlanner 
          apiURL= {this.state.apiURL}
          displayName = {this.state.user.displayName} 
          Email = {this.state.user.email}
          userPrincipalName = {this.state.user.userPrincipalName}
          graphToken = {this.state.graphToken}
      />
        
    ) : (
      'Loading...'
    );
  }
  
  fnManageSupervisor() {
      return this.state.chkUser.length ? (
        <ManageSupervisor 
          apiURL= {this.state.apiURL}
          displayName = {this.state.user.displayName} 
          Email = {this.state.user.email}
          userPrincipalName = {this.state.user.userPrincipalName}
          graphToken = {this.state.graphToken}
      />
        
    ) : (
      'Loading...'
    );
  }
  
  fnManageHR() {
      return this.state.chkUser.length ? (
        <ManageHR 
          apiURL= {this.state.apiURL}
          displayName = {this.state.user.displayName} 
          Email = {this.state.user.email}
          userPrincipalName = {this.state.user.userPrincipalName}
          graphToken = {this.state.graphToken}
      />
        
    ) : (
      'Loading...'
    );
  }

  fnAnalytics() {
    return this.state.chkUser.length ? (
      <Analytics 
        apiURL= {this.state.apiURL}
        displayName = {this.state.user.displayName} 
        Email = {this.state.user.email}
        userPrincipalName = {this.state.user.userPrincipalName}
        graphToken = "browser"
    />
      
  ) : (
    'Loading...'
  );
}

  async getGraphToken(token){
    try{
          const taskOptions = {
              method: 'PUT',
              headers: { 
                    'Content-Type': 'application/json'
                  },
              body: JSON.stringify({ teamsToken: token
          })
        };

       fetch(config.apiURL + 'Graph/putGraphToken', taskOptions)
          .then(function(res) {
              return res.json();
          }).then((json)=> {
              console.log(json.tokenDetails);
              this.setState({
                graphToken: json.tokenDetails
              })
              this.getProfileMSTeams(json.tokenDetails);
          });        
      } catch (err) {
      }
  }

  componentDidMount(){
    microsoftTeams.initialize();
    microsoftTeams.getContext((context) => {
    microsoftTeams.authentication.getAuthToken({
      successCallback: (token) => {
        console.log("msteamstoken: " + token);
      microsoftTeams.appInitialization.notifySuccess();
      this.getGraphToken(token);
      },
      failureCallback: (error) => {
        console.log("msteamserror: " + error)
        microsoftTeams.appInitialization.notifyFailure({
          reason: microsoftTeams.appInitialization.FailedReason.AuthFailed,
          error,
        });
      }
    });
    });
  }

  render() {
    const { classes, theme } = this.props;

    return (
      <div>
        
      <BrowserRouter>
        <div className={classes.root}>
          <AppBar position="static" color="default">
            <Tabs
              value={this.state.value}
              onChange={this.handleChange}
              indicatorColor="primary"
              textColor="primary"
              variant="scrollable"
              scrollButtons="on"
            >
              <div className="logowrapper"><img src={logo} alt="Logo" /></div>
              <div>
                <Tab label="Daily Check-in" component={Link} to="/DailyCheck" wrapped />
              </div>
              <div className={this.state.supAdmin < 1 ? 'hrhidden' : ''}>
                <Tab label="Proxy Check-In" component={Link} to="/TeamSchedule" wrapped/>
              </div>
              <div className={this.state.supAdmin < 1 ? 'hrhidden' : ''}>
                <Tab label="Team Planned Attendance" component={Link} to="/Supervisor" wrapped/>
              </div>
              <div className={this.state.hrAdmin < 1 ? 'hrhidden' : ''}>
                <Tab label="Manage Supervisor" component={Link} to="/ManageSupervisor" wrapped/>
              </div>
              <div className={this.state.hrAdmin < 1 ? 'hrhidden' : ''}>
                <Tab label="Manage HR" component={Link} to="/ManageHR" wrapped />
              </div>
              <div className={this.state.analyticsAdmin < 1 ? 'hrhidden' : ''}>
                <Tab label="Analytics" component={Link} to="/Analytics" wrapped />
              </div>
            </Tabs>
          </AppBar>

          <Switch>
            <Route path="/DailyCheck" component={this.fnDailyCheck} />
            <Route exact path="/TeamSchedule" component={this.fnSupervisors} />
            <Route exact path="/Supervisor" component={this.fnAttendancePlanner} />
            <Route exact path="/ManageSupervisor" component={this.fnManageSupervisor} />
            <Route exact path="/ManageHR" component={this.fnManageHR} />
            <Route exact path="/Analytics" component={this.fnAnalytics} />
            <Route exact path="/">
                <Redirect to="DailyCheck" />
            </Route> 
          </Switch>
        </div>
      </BrowserRouter>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(TeleTrackTeam);