import React from 'react';
import './App.css';
import TeleTrack from './Components/TeleTrack';
import * as microsoftTeams from "@microsoft/teams-js";
//import { getMSteamsGraphApi } from './AD_config/MSUtils.js';
import TeleTrackTeam from './Components/TeleTrackTeam';

class App extends React.Component {

  constructor() {
    super();
    // this.state = {
    //   authInitialized: false
    // }
  }

  // async getProfileMS(token){
  //   try{
  //     const taskOptions = {
  //       method: 'PUT',
  //       headers: { 
  //             'Content-Type': 'application/json'
  //            },
  //       body: JSON.stringify({ teamsToken: token
  //     })
  //     };
  //      fetch('https://api-teletrack-dev.azurewebsites.net/api/Graph/putGraphToken', taskOptions)
  //       .then(function(res) {
  //           return res.json();
  //       }).then((json)=> {
  //           console.log(json.tokenDetails);
  //           this.getProfileMS1(json.tokenDetails);
  //       });        
  //     }catch (err) {
  //     }
  // }

  // async checkgetProfileMS(token){
  //   try{
  //     const taskOptions = {
  //       method: 'GET',
  //       headers: { 
  //             'Content-Type': 'application/json',
  //             'Access-Control-Allow-Origin': '*' 
  //           }
  //     };

  //     await fetch('https://api-teletrack-dev.azurewebsites.net/api/Graph/getGraphToken/dd', taskOptions)
  //         .then(function(res) {
  //           return res.json();
  //         }).then((json)=> {
  //           console.log(json.tokenDetails);
  //           this.getProfileMS1(json.tokenDetails);
  //         });
  //     }catch (err) {
  //     }
  // }

  // async getProfileMS1(token){
  //   const user = await getMSteamsGraphApi(token);
  //   console.log("AppUser: " + user)
  // }

  componentDidMount() {

  }

  render() {
    microsoftTeams.initialize(window);
    return (
              <div className="App">
                <TeleTrackTeam />
              </div>
            );
    // microsoftTeams.initialize(window);
    //  if (microsoftTeams) {
    //      if (window.parent === window.self) {
    //        return (
    //           <div className="App">
    //             <TeleTrack />
    //           </div>
    //        );
    //       } else  {
    //         return (
    //           <div className="App">
    //             <TeleTrackTeam />
    //           </div>
    //         );
    //       }
    //    }


    }

    
}
export default App;

