import React, { Component } from 'react';
import '../Branding/Common.css';
import calendericon from '../Branding/calendar-icon.svg'
import labelicon from '../Branding/label-icon.svg'
import { config } from '../AD_config/Config.js';
 
class Analytics extends Component {

	constructor(props) {
		super(props)	

        var today = new Date(),
		date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
		var view = '';

		var month  = today.getMonth() + 1;
		var day  = today.getDate();
		if(month < 10){
			if(day < 10)
				view = "0" + (today.getMonth() + 1)  + '-0' + today.getDate() + '-' + today.getFullYear();
			else
				view = "0" + (today.getMonth() + 1)  + '-' + today.getDate() + '-' + today.getFullYear();
		}else{
			if(day < 10)
				view = (today.getMonth() + 1)  + '-0' + today.getDate() + '-' + today.getFullYear();
			else
				view =  (today.getMonth() + 1)  + '-' + today.getDate() + '-' + today.getFullYear();
		}

        this.state = {
            viewDate: view
        }
	}

	componentDidMount() {

	}

    render() {
        return (
            <div>
                {/* <div className="header">
                    <h4>ANALYTICS</h4>
                </div> */}
                <div>
                    <iframe width='100%' height='740' src={config.powerBi} frameborder='0' allowFullScreen='true' ></iframe>
                </div>
                {/* <div>
                    <div className="container">
                        <div className="topsection">
                               <div className="form-group">
                                        <label><img src={labelicon} alt ="Name Label Icon" className="label-icon"/>Name</label>
                                        <p>{this.props.displayName}</p> 
                                </div>
                                <div className="form-group">
                                        <label><img src={calendericon} alt ="Calender Icon" className="label-icon"/>Date</label>
                                        <p> {this.state.viewDate}</p>
                                </div>
                                <div className="clear"> </div>

                                {/* <div className="analytics_align"> 
                                    <span className="info-message fl"><i class="fa fa-info-circle" aria-hidden="true"></i>
                                    Please <a href={config.powerBi} target="_blank" className="blink">click me</a> for Report
                                    </span>	
                                </div> 
                               
                        </div>
                    </div>
	            </div>*/}
            </div>
        );
    }
}

export default Analytics