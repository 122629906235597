import React, { Component } from 'react';
import '../Branding/Common.css';
import '../Branding/daily-checkin.css';
import 'font-awesome/css/font-awesome.min.css';
import Swal from 'sweetalert2'
import calendericon from '../Branding/calendar-icon.svg'
import labelicon from '../Branding/label-icon.svg'
import TimeField from 'react-simple-timefield';
import { getApiToken } from '../AD_config/MSUtils.js';

const timeStyle = {
	width: '99%'
  };

class DailyCheck extends Component {
	
	constructor(props) {
		super(props);
		this.getfnApiToken = this.getfnApiToken.bind(this);
		this.handleChangeAttendance = this.handleChangeAttendance.bind(this);
		this.submit = this.submit.bind(this);
		this.getRoom =  this.getRoom.bind(this);
		this.handleChangeRoom = this.handleChangeRoom.bind(this);
		this.handleCheckAll = this.handleCheckAll.bind(this);
		this.getDailycheckindetails = this.getDailycheckindetails.bind(this);
		this.handleRemoveRow = this.handleRemoveRow.bind(this);
		this.getDCPlannedAttendance = this.getDCPlannedAttendance.bind(this);
		
		var today = new Date(),
		date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
		var view = '';

		var month  = today.getMonth() + 1;
		var day  = today.getDate();
		if(month < 10){
			if(day < 10)
				view = "0" + (today.getMonth() + 1)  + '-0' + today.getDate() + '-' + today.getFullYear();
			else
				view = "0" + (today.getMonth() + 1)  + '-' + today.getDate() + '-' + today.getFullYear();
		}else{
			if(day < 10)
				view = (today.getMonth() + 1)  + '-0' + today.getDate() + '-' + today.getFullYear();
			else
				view =  (today.getMonth() + 1)  + '-' + today.getDate() + '-' + today.getFullYear();
		}

		this.state = {
			currDate: date,//'2020-12-28',
			viewDate: view,
			pSave: '',
			rows: [],
			hrows: [],
			checkedBoxes: [],
			roomValues: [],
			attendanceValues: [],
			adDisplayName: '',
			adRoom: '0',
			adDivision: '',
			adAttendance: '',
			checkedall: false,
			postRes: '',
			errorshours: {},
			errorstask: {},
			plannedattendance: 'Not scheduled'
			//pday: '',
			//pmonth: '',
			//pweekname: ''
		};
	}

	async getfnApiToken() {
		const apiToken = await getApiToken();
		if(apiToken){
			this.getRoom(apiToken);
			this.getAttendance(apiToken);
		}
	}

	async getDCPlannedAttendance() {
		const apiToken = await getApiToken();
		if(apiToken){
			
		 var currentDate = this.state.currDate;
		// var startDate = new Date(currentDate);
		// var day = new Date(startDate).getDate();
		// var month = startDate.toLocaleString('default', { month: 'long' });
		// var weekname = startDate.toLocaleString('default', { weekday: 'long' });

		var stremail = this.props.Email;
			
			if(this.props.Email == null || this.props.Email == '')
				stremail = this.props.userPrincipalName;

				const taskOptions = {
					method: 'GET',
					headers: { 
								'Content-Type': 'application/json',
								'Authorization': 'Bearer ' + apiToken, 
							}
				};
	
				await fetch(this.props.apiURL + 'Daily/getDailycheckPlannedAttendance/'  + stremail + "/" + currentDate, taskOptions)
				.then(function(res) {
					return res.json();
					
				}).then((json)=> {
					if(json.employeeDetails.length > 0){
						//console.log(json.employeeDetails[0].AttendanceStatus);
						this.setState({
							plannedattendance: json.employeeDetails[0].AttendanceStatus
							//pday : day,
							//pmonth : month,
							//pweekname : weekname
						})
					}
				});			  
		}
	}

	async getDailycheckindetails() {

		const apiToken = await getApiToken();
		if(apiToken){
			
			var currentDate = this.state.currDate;
			var stremail = this.props.Email;
			if(this.props.Email == null || this.props.Email == '')
				stremail = this.props.userPrincipalName;
			
			const taskOptions = {
					method: 'GET',
					headers: { 
								'Content-Type': 'application/json',
								'Authorization': 'Bearer ' + apiToken, 
							}
				};
			
			await fetch(this.props.apiURL + 'Daily/getDailyCheckIn/' + stremail + "/" + currentDate, taskOptions)
			.then(function(res) {
				return res.json();
			}).then((json)=> {
				if(json.dailyDetails.length > 0){
					
					this.setState({
							adRoom: json.dailyDetails[0].adRoom,
							adDivision: json.dailyDetails[0].adDivision,
							adAttendance: json.dailyDetails[0].AttendanceStatus
						})
						this.refs.rComments.value = json.dailyDetails[0].Comments;
				}else{
					if(this.props.attrRoom){
					this.setState({
						adRoom: this.props.attrRoom.toUpperCase()
					});
				}
				}
			});

			await fetch(this.props.apiURL + 'Daily/getDailyTask/' + stremail + "/" + this.state.currDate, taskOptions)
			.then(function(res) {
				return res.json();
			}).then((json)=> {
				this.setState({ 
					rows: json.dailyDetails,
					hrows: json.dailyDetails  
				})
			});
		}
	  }

	async submit() {
	
	try{
		const apiToken = await getApiToken();
		if(apiToken){
		var stremail = this.props.Email;
		if(this.state.adDisplayName == null || this.state.adDisplayName == ""){
			Swal.fire({
				icon: 'error',
				title: 'Display name is required!'
			  })
			return;
		}

		if(this.state.adDivision == null || this.state.adDivision == ""){
			Swal.fire({
				icon: 'error',
				title: 'Division is required!'
			  })
			return;
		}

		if(this.state.adRoom == null || this.state.adRoom == "" || this.state.adRoom == "--Select--"){
			Swal.fire({
				icon: 'error',
				title: 'Please select room!'
			  })
			return;
		}

		if(this.state.adAttendance == null || this.state.adAttendance == "" || this.state.adAttendance == "--Select--"){
			Swal.fire({
				icon: 'error',
				title: 'Please select attendance!'
			  })
			return;
		}

		if(this.props.Email == null || this.props.Email == '')
			stremail = this.props.userPrincipalName;

	if(this.validatehours()){
		const requestOptions = {
			method: 'PUT',
			headers: { 
						'Content-Type': 'application/json',
						'Authorization': 'Bearer ' + apiToken 
					 },
			body: JSON.stringify({ 	employeeName: this.state.adDisplayName,
									email: stremail,
									date: this.state.currDate,
									divisionName: this.state.adDivision,
									roomName: this.state.adRoom,
									attendanceStatus: this.state.adAttendance,
									comments: this.refs.rComments.value,
									recordedBy: 'E',
		 })
		};

		await fetch(this.props.apiURL + 'Daily', requestOptions)
				.then(function(response) {
					return response.json();
					
				}).then((data)=> {
					if(data.dailyDetails.length > 0){
						this.setState({
							postRes: data.dailyDetails[0].res,
						})
					}
				});

		if(this.state.postRes == "1"){
			let nrows = [...this.state.rows]
			if(nrows.length > 0){
				for(var i=0; i < nrows.length; i++){
					var hour = 0;
					var minute = 0;
					var taskdate = this.state.currDate;
					var taskhours= this.state.hrows[i].hours;
					var taskName = this.state.rows[i].task;
					var taskid = this.state.rows[i].taskid;
					if(taskhours){
						if(taskhours != null || taskhours != ''){	
							var arr = taskhours.split(':');
							if(arr[0])
								hour = arr[0];
							if(arr[1])
								minute = arr[1];
					const taskOptions = {
						method: 'PUT',
						headers: { 
									'Content-Type': 'application/json',
									'Authorization': 'Bearer ' + apiToken 
								 },
						body: JSON.stringify({ email: stremail,
												date: taskdate,
												taskName: taskName,//this.state.rows[i].task,
												hours: hour,//this.state.hrows[i].hours,
												iscompleted: false,
												minute: minute,
												taskid: taskid
					})
					};
					 fetch(this.props.apiURL + 'Daily/Task', taskOptions)
							.then(function(response) {
								return response.json();
								
							}).then((data)=> {
								this.getDailycheckindetails();
							});	
				}
			}

				}
			}
			Swal.fire({
				icon: 'success',
				title: 'Data saved successfully'
			  })
		}
	}
}
	}catch(err){
		throw err;
	}

	  }

	  handleChangeAttendance(event) {
		this.setState({
			adAttendance: event.target.value
		});
	  }

	  handleChangeRoom(event) {
	  this.setState({
		adRoom: event.target.value
	  });
	}

	  handleChangeTask = idx => e => {
		const { name, value } = e.target;
		const rows = [...this.state.rows];
		if(rows[idx].taskid === undefined){
			rows[idx] = {
			[name]: value,
			"taskid": 0
			};
		}else{
			rows[idx] = {
				[name]: value,
				"taskid": rows[idx].taskid
			}
		}
		this.setState({
		  rows
		});
	  };

	  handleChangeHour = idx => e => {
		const { name, value } = e.target;
		const hrows = [...this.state.hrows];
		hrows[idx] = {
		  [name]: value
		};
		this.setState({
		  hrows
		});
	  };

	  handleAddRow = (idx) => {
		const item = {
		  chkRow: "",
		  task: "",
		  hours: "00:00"
		};
		this.setState({
		  rows: [...this.state.rows, item],
		  hrows: [...this.state.hrows, item]
		});

	  }

	  async handleRemoveRow() {
		try{
			let mrows = [...this.state.rows]
			const apiToken = await getApiToken();
			if(apiToken){
			if(this.state.checkedall === true || this.state.checked === true){

				Swal.fire({
					title: 'Are you sure?',
					text: "You won't be able to revert this!",
					icon: 'warning',
					showCancelButton: true,
					confirmButtonColor: '#3085d6',
					cancelButtonColor: '#d33',
					confirmButtonText: 'Yes, delete it!'
				  }).then((result) => {
					if (result.isConfirmed) {
						if(this.state.checkedall == false) {
							for(var i=0; i < mrows.length; i++){
								if(this.refs["check_row" + i].checked === true){
									if(this.refs["task_id" + i].value){
										
									var taskID = this.refs["task_id" + i].value;
									const taskOptions = {
										method: 'PUT',
										headers: { 
													'Content-Type': 'application/json',
													'Authorization': 'Bearer ' + apiToken 
												 },
										body: JSON.stringify({ taskid: taskID
									})
									};
									 fetch(this.props.apiURL + 'Daily/deleteDailyTask', taskOptions)
											.then(function(response) {
												return response.json();
												
											}).then((data)=> {
												this.getDailycheckindetails();
											});
								}
							}
								this.refs["check_row" + i].checked = false;
							  }
						}else {

							for(var i=0; i < mrows.length; i++){
								if(this.refs["check_row" + i].checked === true){
									if(this.refs["task_id" + i].value){
									var taskID = this.refs["task_id" + i].value;
									const taskOptions = {
										method: 'PUT',
										headers: { 
													'Content-Type': 'application/json',
													'Authorization': 'Bearer ' + apiToken 
												 },
										body: JSON.stringify({ taskid: taskID
									})
									};
									 fetch(this.props.apiURL + 'Daily/deleteDailyTask', taskOptions)
											.then(function(response) {
												return response.json();
												
											}).then((data)=> {
												this.getDailycheckindetails();
											});
								}
							}
								this.refs["check_row" + i].checked = false;
							  }
						}
						this.setState({
							checkedall: false,
							checked: false
						})
						Swal.fire(
							'Deleted!',
      						'success'
						  )
					}
				  })
				}
			}
		}catch(err){
			throw err;
		}

	  }

	handleCheckboxChange = idx => e => {
		this.setState({
			checkedall: false,
			checked: false
		})
		 if (e.target.checked) {
			this.setState({
				checked: true
			})
			e.target.checked = true;
		 }
	  }

	  handleCheckAll= e => {
		 
		let nrows = [...this.state.rows]	
		this.setState({
			checkedall: false,
			checked: false
		})

		for(var i=0; i < nrows.length; i++){
			this.refs["check_row" + i].checked = false;
		  }

		if (e.target.checked) {
		  this.setState ({
			   checkedall: true	
		  })

		  for(var i=0; i < nrows.length; i++){
			this.refs["check_row" + i].checked = true;
		  }
		}
	  }


	   async getRoom(apitoken) {

		let adroomName = "--Select--"
		if(this.props.attrRoom != null || this.props.attrRoom != "") {
			if(this.props.attrRoom){
			adroomName = this.props.attrRoom.toUpperCase();
			}
		}
		
		if(apitoken){
			const taskOptions = {
				method: 'GET',
				headers: { 
							'Content-Type': 'application/json',
							'Authorization': 'Bearer ' + apitoken, 
						}
			};

			await fetch(this.props.apiURL + 'Room/getRoom/' + adroomName, taskOptions)
			.then(function(res) {
				return res.json();
				
			}).then((json)=> {
				this.setState({
					roomValues: json.roomResults,
				})
			});
		}				  
	  }

	  async getAttendance(apitoken) {
		if(apitoken){
			const taskOptions = {
				method: 'GET',
				headers: { 
							'Content-Type': 'application/json',
							'Authorization': 'Bearer ' + apitoken, 
						}
			};
			await fetch(this.props.apiURL + 'Attendance', taskOptions)
			.then(function(res) {
				return res.json();
				
			}).then((json)=> {
				this.setState({
					attendanceValues: json.roomResults
				})
			});
		}			  
	  }

	  validatehours(){
		let errorshours = {};
		let errorstask = {};
		let isValidhours = true;

		let taskrows = [...this.state.rows]	
				if(taskrows.length > 0){
					for(var i=0; i < taskrows.length; i++){
						if(this.state.rows[i]){
							if(!this.state.rows[i].task){
								isValidhours = false;
								errorstask[i] = "Task is required!";
							}
						}
					}
				}

		let hoursrows = [...this.state.hrows]	
				if(hoursrows.length > 0){
					for(var i=0; i < hoursrows.length; i++){
						if(this.state.hrows[i]){
							if(!this.state.hrows[i].hours || this.state.hrows[i].hours == "00:00"){
								isValidhours = false;
								errorshours[i] = "Hours is required!";
							}
						}
					}
				}
	
		this.setState({
			errorstask: errorstask,
			errorshours: errorshours
		});
	
		return isValidhours;
	}

	componentDidMount() {
		this.setState({
			adDisplayName: this.props.displayName,
			adDivision: this.props.attrDivision
		});
		this.getfnApiToken();
		//this.getRoom();
		//this.getAttendance();
		this.getDailycheckindetails();
		this.getDCPlannedAttendance();

	  }

  render() {


    return (
		<div className="daily-checkin-wrapper">
		
		<div className="header">
		   <h4>Daily Check-in</h4>
		</div>
		 <div className="container">
		   <div className="topsection">
			 <div className="form-group">
							   <label><img src={labelicon} alt ="Name Label Icon" className="label-icon"/>Name</label>
							   {<p>{this.state.adDisplayName}</p>}
					   </div>
			 <div className="form-group">
							   <label><img src={calendericon} alt ="Calender Icon" className="label-icon"/> Date</label>
							   <p> {this.state.viewDate}</p>
					   </div>
			 <div className="form-group">
							   <label><img src={labelicon} alt ="Name Label Icon" className="label-icon"/>Division</label>
							   <p> {this.state.adDivision}</p>
							
					   </div>
			 <div className="form-group">
							   <label><img src={labelicon} alt ="Name Label Icon" className="label-icon"/>Room or Area</label>
							  <p>
							   <select value={this.state.adRoom} onChange={this.handleChangeRoom} >{
					this.state.roomValues.map((room) => {
						return <option value={room.RoomName}>{room.RoomName}</option>
					})
				 }</select>
				 </p>
					   </div>
			 <div className="clear"> </div>
			 <div className="dailyPA">Planned attendance: "{this.state.plannedattendance}"</div>
		   </div>
		   <div className="middle-section">
			 <div> 
			   <div className="col-50"> 
				 <div className="inner-container">
				   <h4>Attendance</h4>
					 <div className="gray-bg w-300">
   
					 {this.state.attendanceValues.map((option) => (
					<div className="radio-wrapper"> 
						<input
						id={option.AttendanceStatus}
						type="radio"
						name="Attendance"
						value={option.AttendanceStatus}
						checked={this.state.adAttendance === option.AttendanceStatus}
						onChange={this.handleChangeAttendance}
						/>
						<label>{option.AttendanceStatus}</label>
					</div>
					))}
					 </div>
				 </div>
			   </div>
			   <div className="col-50"> 
								   <div className="inner-container">
									   <h4>Today's Task &nbsp;&nbsp;
									   <span className="info-message"><i class="fa fa-info-circle" aria-hidden="true"></i>
					Hours: 00-23 and Minutes: 00-59</span>
									   </h4>
									   <div className="gray-bg">
											   <div className="table-top-menu">
												   <button onClick={this.handleAddRow} className="btn btn-default pull-left"><i className="fa fa-plus" aria-hidden="true"></i>&nbsp;Add</button>
												   <button onClick={this.handleRemoveRow} className="btn btn-default"><i className="fa fa-trash" aria-hidden="true"></i>&nbsp;Delete</button>
											   </div>
											   <div className="table-responsive">
											   <table
				   className="table table-bordered table-hover"
				   id="tab_logic"
				 >
				   <thead>
					 <tr>
					 <th className="hrhidden"> # </th>
					   <th className="text-center" width="30">
							<input 
								 type="checkbox" 
								 name="chkAll"
							   onChange={this.handleCheckAll.bind(this)}
							   checked={this.state.checkedall}  
						   />
					   </th>
					   <th className="text-center"> Tasks </th>
					   <th className="text-center" width="100"> Hours:Minutes </th>
					 </tr>
				   </thead>
				   <tbody>
					 {this.state.rows.map((item, idx) => (
					   <tr id="addr0" key={idx}>
						 <td className="hrhidden">{idx}</td>
						 <td>
						   <input 
							 type="checkbox" 
							 name="chkRow"
							 onChange={this.handleCheckboxChange(idx)}
							 ref={"check_row" + idx}
							 //checked={this.state.chkValue[idx]}
							 //value={this.state.chkValue[idx]}
							 id={idx}
							 //onClick={this.toggleCheckbox(idx)} 
							 />
						 </td>
				
						 <td>
						   <input
							 type="text"
							 name="task" 
							 value={this.state.rows[idx].task}
							 onChange={this.handleChangeTask(idx)}
							 className="form-control"
						   />
<div className="text-danger-task">{this.state.errorstask[idx]}</div>
						 </td>
						 {/* <td>
						    <input
							 type="text"
							 name="hours"
							 value={this.state.hrows[idx].hours}
							 onChange={this.handleChangeHour(idx)}
							 onKeyPress={this.handlekeypressHour}
							 onKeyDown={this.onKeyDown}
							 onKeyUp={this.onKeyUp}
							 className="form-control"
							 maxLength="5"
						   /> 

						   
						 </td> */}
						 <td>
						 <TimeField 
						 			name="hours" 
						 			style={timeStyle} 
									className="timefield form-control" 
									value={this.state.hrows[idx].hours} 
									onChange={this.handleChangeHour(idx)} />
						 
						 <input
							 type="hidden"
							 name="taskid" 
							 value={this.state.rows[idx].taskid}
							 ref={"task_id" + idx}
							 //onChange={this.handleChangeTask(idx)}
							 className="form-control"
						   />
						 <div className="text-danger-task">{this.state.errorshours[idx]}</div>
						 </td>
					   </tr>
					 ))}
				   </tbody>
				 </table>
											 </div>
									   </div>
							   </div>
						 </div>
						 <div className="clear"> </div>
			 </div>
			 <div className="mr-tp-15">
						   <div className="col-50 comments-wrapper">
								   <div className="form-group">
									   <label>Comments</label>
									   <textarea id="Comments" name="w3review" ref="rComments" rows="10" cols="50"> </textarea>
							   </div>
						   </div>
						   <div className="col-50">
							   <div className="btn-wrapper">
							   <button
								   className="btn-primary" 
								   value="Save"
								   onClick={this.submit}> 
								   Save
							   </button>
							   </div>
							   <div className="psave"><p> {this.state.pSave}</p></div>
						   </div>
					   <div className="clear"> </div>
					   <div className="clear"> </div>
					   </div>
		   </div>
		 </div>
	   </div>
	   );
	 }
   }
   
   export default DailyCheck