import React, { Component } from 'react';
import '../Branding/Common.css';
import 'font-awesome/css/font-awesome.min.css';
//import WayneRancourt from '../Branding/WayneRancourt.jpg'
import { UserAgentApplication } from 'msal';
import { config } from '../AD_config/Config.js';
import { normalizeError, getSingleProfile, getMSteamsGraphApiSingleUser } from '../AD_config/MSUtils.js';
import Swal from 'sweetalert2';
import { getApiToken } from '../AD_config/MSUtils.js';
//var parse = require('html-react-parser');

class ManageSupervisor extends Component {

	constructor(props) {
		super(props);
		this.getCheckHRAdmin = this.getCheckHRAdmin.bind(this);
		this.getHRdetails = this.getHRdetails.bind(this);
		this.handleCheckAll = this.handleCheckAll.bind(this);
		this.singleUser = this.singleUser.bind(this);
		this.singleUsergraph = this.singleUsergraph.bind(this);
		this.submit = this.submit.bind(this);
		this.togglePopProxy = this.togglePopProxy.bind(this);
		this.handleCheckAllproxy = this.handleCheckAllproxy.bind(this);
		this.cancel = this.cancel.bind(this);
		this.cancelproxy = this.cancelproxy.bind(this);
		this.submitproxy = this.submitproxy.bind(this);
		this.handleRemoveRow = this.handleRemoveRow.bind(this);
		this.handleRemoveRowproxy = this.handleRemoveRowproxy.bind(this);

		this.state = {
			pSave: '',
			rows: [],
			hrows: [],
			prows: [],
			namerows: [],
			adEmail: '',
			adRoom: '',
			adDivision: '',
			postRes: '',
			hrHide: false,
			hrAuthorization: true,
			supemail: '',
			checkedall: false,
			proxyrows: [],
			checkedallproxy: false,
			seen: true,
			supervisorvalue: '',
			btnstatus: false,
			proxystatus: false,
			proxyadroom: '',
			proxyaddivision: '',
			proxysupervisoremail: '',
			proxysupervisorName: '',
			proxysave: false,
			btnAddDelStatus: false,
			btnAddProxy: false,
			btnSaveProxy: true,
			errors: {},
			errorsproxy: {},
			tapiToken: ''	
		};
	}

	async getCheckHRAdmin(){
		const apiToken = await getApiToken();
		if(apiToken){
			this.setState({
				tapiToken: apiToken
			})
			var email = this.props.Email;
			if(this.props.Email == null || this.props.Email == '')
			email = this.props.userPrincipalName;
			const taskOptions = {
				method: 'GET',
				headers: { 
							'Content-Type': 'application/json',
							'Authorization': 'Bearer ' + apiToken, 
						}
			};
			
			await fetch(this.props.apiURL + 'Daily/checkHRAdmin/' + email + "/", taskOptions)
			.then(function(res) {
				return res.json();
			}).then((json)=> {
				if(json.hrDetails.length > 0){			  
					this.setState({
						hrHide: true,
						hrAuthorization: false,
					})
				}
			});
		}
	}

	async getHRdetails() {

		const apiToken = await getApiToken();
		if(apiToken){
			this.setState({
				errors: {}
			})
			var stremail = this.props.Email;
			if(this.props.Email == null || this.props.Email == '')
				stremail = this.props.userPrincipalName;

			const taskOptions = {
				method: 'GET',
				headers: { 
							'Content-Type': 'application/json',
							'Authorization': 'Bearer ' + apiToken, 
						}
			};

			await fetch(this.props.apiURL + 'Daily/getSupervisorAdminDetails/' + stremail + "/", taskOptions)
			.then(function(res) {
				return res.json();
			}).then((json)=> {
				if(json.supadminDetails.length > 0){						
					this.setState({  
						rows: json.supadminDetails,
						hrows: json.supadminDetails,
						prows: json.supadminDetails,
						namerows: json.supadminDetails,
						btnstatus: false,
						proxystatus: false
					})
				}
			});
		}
	  }

	  cancel(){
		  this.getHRdetails();
		  this.setState({
			  btnstatus: false,
			  proxystatus: false,
			  btnAddDelStatus: false
		  })
	  }

	  cancelproxy(){	
		this.setState({
			btnAddProxy: false,
			btnSaveProxy: true,
			seen: true
		})
	}

	async submit() {

	try{
		const apiToken = await getApiToken();
		if(apiToken){									
			var stremail = this.props.Email;
			if(this.props.Email == null || this.props.Email == '')
				stremail = this.props.userPrincipalName;
						
				if(this.validate()){
					let nrows = [...this.state.rows]	
					if(nrows.length > 0){
						for(var i=0; i < nrows.length; i++){
							if(this.state.rows[i]){
								this.state.errors[i] = "";
								if(!this.state.namerows[i].supname){
									Swal.fire({
										icon: 'error',
										title: 'Please Enter email and press Tab to locate Supervisor!'
									})
									return;
								}
							}
						}
					}
					
					if(nrows.length > 0){
						for(var i=0; i < nrows.length; i++){
							var roomName = "";
							var divisionname = "";
							if(this.state.hrows[i].supdivision)
								divisionname = this.state.hrows[i].supdivision;

							if(this.state.prows[i].suproom)
								roomName = this.state.prows[i].suproom;

							const taskOptions = {
								method: 'PUT',
								headers: { 
											'Content-Type': 'application/json',
											'Authorization': 'Bearer ' + apiToken 
										 },
								body: JSON.stringify({ email: this.state.rows[i].supemail.toLowerCase(),
														supadminname: this.state.namerows[i].supname,
														divisionname: divisionname,
														roomname: roomName,
														updatedby: stremail,
														count: 0
							})
							};
							await fetch(this.props.apiURL + 'Daily/ManageSupervisor', taskOptions)
									.then(function(response) {
										return response.json();
										
									}).then((data)=> {
											this.setState({
												postRes: "1"
											})
									});	
		
						}
						if(this.state.postRes == "1"){
							this.getHRdetails();
							this.setState({
								btnstatus: false,
								proxystatus: false,
								btnAddDelStatus: false
							})
							Swal.fire({
								icon: 'success',
								title: 'Data saved successfully'
							})
						}
					}else{
						Swal.fire({
							icon: 'error',
							title: 'Please enter supervisor details!'
						})
						return;
					}
				}
		}
		}catch(err){
			throw err;
		}
	}

		  
	  _handleKeyDown = idx => e => {										
		if (e.key === 'Enter' || e.key === 'Tab') {
			var cvalue = 0;
			this.state.rows.map(function(item, i){
				if(i != idx){
					if(cvalue == 0){
						if(e.target.value.toLowerCase() == item["supemail"]){
							Swal.fire({
								icon: 'error',
								title: 'Duplicate value not allowed!'
							  })
							e.target.value = "";
							cvalue = 1;
							return;
						}
					}
				}
			  })
			if(cvalue == 0){
				this.state.supervisorvalue = "supervisor";
				this.setState({
					proxysave: true
				})

				if(this.props.graphToken != "browser")
					this.singleUsergraph(e.target.value, idx);
				else
					this.singleUser(e.target.value, idx);
			}
		}
	  }
	  
	handleChangeemail = idx => e => {
		const { name, value } = e.target;
		const rows = [...this.state.rows];
		rows[idx] = {
		  [name]: value.toLowerCase()
		};
		this.setState({
		  rows
		});
								 
	  };
	  
	  handleChangedivision = (idx, val) => {				
		const hrows = [...this.state.hrows];
		if(val == null || val == ""){
			hrows[idx] = {
			["supdivision"]: ""
			};
		}else{
			hrows[idx] = {
				["supdivision"]: val
			};
		}
		this.setState({
		  hrows
		});
	  };

	  handleChangeroom = (idx, val) => {
		const prows = [...this.state.prows];
		if(val == null || val == ""){
			prows[idx] = {
			["suproom"]: ""
			};
		}else{
			prows[idx] = {
				["suproom"]: val
			};
		}
		this.setState({
		  prows
		});
	  };

	  handleChangename = (idx, val) => {
		const namerows = [...this.state.namerows];
		if(val == null || val == ""){
			namerows[idx] = {
			["supname"]: ""
			};
		}else{
			namerows[idx] = {
				["supname"]: val
			};
		}
		this.setState({
			namerows
		});
	  };
	  

	handleAddRow = (idx) => {
		const item = {
		  chkRow: ""
		};
		this.setState({
		  rows: [...this.state.rows, item],
		  hrows: [...this.state.hrows, item],
		  prows: [...this.state.prows, item],
		  namerows: [...this.state.namerows, item],
		  btnstatus: true,
		  proxystatus: true,
		  btnAddDelStatus: true
		});
	  }

	  async handleRemoveRow() {
		try{	
			const apiToken = await getApiToken();
			if(apiToken){						 
				if(this.state.checkedall === true || this.state.checked === true){
				Swal.fire({
					title: 'Are you sure?',
					text: "You won't be able to revert this!",
					icon: 'warning',
					showCancelButton: true,
					confirmButtonColor: '#3085d6',
					cancelButtonColor: '#d33',
					confirmButtonText: 'Yes, delete it!'
				}).then((result) => {
					if (result.isConfirmed) {
						if(this.state.checkedall == false) {
							for(var i=0; i < this.state.rows.length; i++){

								if(this.refs["check_row" + i]){
								if(this.refs["check_row" + i].checked === true){
									var superadminid = 0;
									if(this.refs["Supervisor_ID" + i]){
											superadminid = this.refs["Supervisor_ID" + i].value;

									const taskOptions = {
										method: 'PUT',
										headers: { 
													'Content-Type': 'application/json',
													'Authorization': 'Bearer ' + apiToken 
												 },
										body: JSON.stringify({ superadminid: superadminid
									})
									};
									fetch(this.props.apiURL + 'Daily/deleteSupervisorAdminById', taskOptions)
											.then(function(response) {
												return response.json();
												
											}).then((data)=> {
												this.getHRdetails();
											});
								}
							}
						}
								this.refs["check_row" + i].checked = false;
							}
						}else {

							for(var i=0; i < this.state.rows.length; i++){
								if(this.refs["check_row" + i]){
								if(this.refs["check_row" + i].checked === true){
									var superadminid = 0;
									if(this.refs["Supervisor_ID" + i]){
											superadminid = this.refs["Supervisor_ID" + i].value;
									const taskOptions = {
										method: 'PUT',
										headers: { 
													'Content-Type': 'application/json',
													'Authorization': 'Bearer ' + apiToken 
												 },
										body: JSON.stringify({ superadminid: superadminid
									})
									};
									fetch(this.props.apiURL + 'Daily/deleteSupervisorAdminById', taskOptions)
											.then(function(response) {
												return response.json();
												
											}).then((data)=> {
												this.getHRdetails();
											});
								}
							}
						}
								this.refs["check_row" + i].checked = false;
							}
						}
						this.setState({
							rows: [],
							hrows: [],
							checkedall: false,
							checked: false
						})

						Swal.fire(
							'Deleted!',
							'success'
						)			
					}
				})

				}
		}
		}catch(err){
				  
			throw err;
		}

	  }

	  handleCheckboxChange = idx => e => {
		this.setState ({
			checkedall: false,
			checked: false
	   })
		this.state.checkedall = false
		if (e.target.checked) {
			this.setState ({
				checked: true
		   })
			e.target.checked = true;
		}
	  }

	  handleCheckAll= e => {
		let nrows = [...this.state.rows]	
		this.setState({
			 checkedall: false,	
			 checked: false
		})

		for(var i=0; i < nrows.length; i++){
			this.refs["check_row" + i].checked = false;
		  }

		if (e.target.checked) {

		  this.setState ({
			   checkedall: true	
		  })

		  for(var i=0; i < nrows.length; i++){
			this.refs["check_row" + i].checked = true;
		  }
		}
	  }

	  async singleUsergraph(supemail, idx) {												   
		try {
  
		  //const suser = await getSingleProfile(userAgentApplication, config.scopes, supemail);
		  const suser = await getMSteamsGraphApiSingleUser(this.props.graphToken, supemail);
		  if(suser){
		  
		  if(suser.value[0]){
		  
		  this.setState({
		  isAuthenticated: true,
		  suser: {
			sdisplayName: suser.value[0].displayName,
			semail: suser.value[0].mail || suser.value[0].userPrincipalName,
			sid: suser.value[0].id,
			sattrRoom: suser.value[0].onPremisesExtensionAttributes.extensionAttribute4,
			sattrDivision: suser.value[0].onPremisesExtensionAttributes.extensionAttribute5,
		  },
		  error: null
		  });

		 if(this.state.supervisorvalue === "supervisor"){
			this.refs["supdivision" + idx].value = suser.value[0].onPremisesExtensionAttributes.extensionAttribute5;
			this.refs["suproom" + idx].value = suser.value[0].onPremisesExtensionAttributes.extensionAttribute4;
			this.refs["supname" + idx].value = suser.value[0].displayName;
			this.handleChangedivision(idx, suser.value[0].onPremisesExtensionAttributes.extensionAttribute5);
			this.handleChangeroom(idx, suser.value[0].onPremisesExtensionAttributes.extensionAttribute4);
			this.handleChangename(idx, suser.value[0].displayName);
		 }else{
			var stremail = this.props.Email;
			var tapiToken = this.state.tapiToken;

			if(this.props.Email == null || this.props.Email == '')
				stremail = this.props.userPrincipalName;

				var roomName = "";
				var divisionname = "";
				var displayName = suser.value[0].displayName;
				var supervisormail = this.state.proxysupervisoremail;
				var  proxyemail = supemail;
				
					if(suser.value[0].onPremisesExtensionAttributes.extensionAttribute4)
						roomName = suser.value[0].onPremisesExtensionAttributes.extensionAttribute4;

					if(suser.value[0].onPremisesExtensionAttributes.extensionAttribute5)	
						 divisionname = suser.value[0].onPremisesExtensionAttributes.extensionAttribute5;

					 this.refs["proxyadname" + idx].value = displayName;

					if(this.state.proxysave === false){
					const taskOptions = {
						method: 'PUT',
						headers: { 
									'Content-Type': 'application/json',
									'Authorization': 'Bearer ' + tapiToken, 
								 },
						body: JSON.stringify({ email: supervisormail,
							ProxyEmail: proxyemail.toLowerCase(),
							updatedby: stremail,
							employeeName: displayName,
							divisionName: divisionname,
							roomName: roomName,
							count: 0
					})
					};
					fetch(this.props.apiURL + 'Daily/updatemanagesupervisorproxy', taskOptions)
							.then(function(response) {
								return response.json();
								
							}).then((data)=> {
								this.getHRdetails();
							});	
						}

						this.setState({
							proxysave: false
						})
		 }
		 

		}else{
			Swal.fire({
				icon: 'error',
				title: 'Email not found, please validate and try again!'
			  })
			  if(this.state.supervisorvalue === "supervisor"){
				this.refs["supemail" + idx].value = "";
				this.refs["supdivision" + idx].value = "";
				this.refs["suproom" + idx].value = "";
				this.refs["supname" + idx].value = "";
			  }else{
				this.refs["proxyemail" + idx].value = "";
				this.refs["proxyadname" + idx].value = "";
				
			  }
			return;
		}
		}
		}
		catch (err) {
		  this.setState({
		  isAuthenticated: false,
		  suser: {},
		  error: normalizeError(err)
		  });
		}
	  }


	  async singleUser(supemail, idx) {												   
		try {
		  let userAgentApplication = new UserAgentApplication({
			auth: {
			  clientId: config.clientId,
			  redirectUri: window.location.origin, 
			  authority: config.authority,
			  navigateToLoginRequestUrl: false,
			},
			cache: {
			  cacheLocation: "localStorage",
			  storeAuthStateInCookie: true
			}
			});
  
		  const suser = await getSingleProfile(userAgentApplication, config.scopes, supemail);
		  if(suser){
		  
		  if(suser.value[0]){
		  
		  this.setState({
		  isAuthenticated: true,
		  suser: {
			sdisplayName: suser.value[0].displayName,
			semail: suser.value[0].mail || suser.value[0].userPrincipalName,
			sid: suser.value[0].id,
			sattrRoom: suser.value[0].onPremisesExtensionAttributes.extensionAttribute4,
			sattrDivision: suser.value[0].onPremisesExtensionAttributes.extensionAttribute5,
		  },
		  error: null
		  });

		 if(this.state.supervisorvalue === "supervisor"){
			this.refs["supdivision" + idx].value = suser.value[0].onPremisesExtensionAttributes.extensionAttribute5;
			this.refs["suproom" + idx].value = suser.value[0].onPremisesExtensionAttributes.extensionAttribute4;
			this.refs["supname" + idx].value = suser.value[0].displayName;
			this.handleChangedivision(idx, suser.value[0].onPremisesExtensionAttributes.extensionAttribute5);
			this.handleChangeroom(idx, suser.value[0].onPremisesExtensionAttributes.extensionAttribute4);
			this.handleChangename(idx, suser.value[0].displayName);
		 }else{
			var stremail = this.props.Email;
			var tapiToken = this.state.tapiToken;

			if(this.props.Email == null || this.props.Email == '')
				stremail = this.props.userPrincipalName;

				var roomName = "";
				var divisionname = "";
				var displayName = suser.value[0].displayName;
				var supervisormail = this.state.proxysupervisoremail;
				var  proxyemail = supemail;
				
					if(suser.value[0].onPremisesExtensionAttributes.extensionAttribute4)
						roomName = suser.value[0].onPremisesExtensionAttributes.extensionAttribute4;

					if(suser.value[0].onPremisesExtensionAttributes.extensionAttribute5)	
						 divisionname = suser.value[0].onPremisesExtensionAttributes.extensionAttribute5;

					 this.refs["proxyadname" + idx].value = displayName;

					if(this.state.proxysave === false){
					const taskOptions = {
						method: 'PUT',
						headers: { 
									'Content-Type': 'application/json',
									'Authorization': 'Bearer ' + tapiToken, 
								 },
						body: JSON.stringify({ email: supervisormail,
							ProxyEmail: proxyemail.toLowerCase(),
							updatedby: stremail,
							employeeName: displayName,
							divisionName: divisionname,
							roomName: roomName,
							count: 0
					})
					};
					fetch(this.props.apiURL + 'Daily/updatemanagesupervisorproxy', taskOptions)
							.then(function(response) {
								return response.json();
								
							}).then((data)=> {
								this.getHRdetails();
							});	
						}

						this.setState({
							proxysave: false
						})
		 }
		 

		}else{
			Swal.fire({
				icon: 'error',
				title: 'Email not found, please validate and try again!'
			  })
			  if(this.state.supervisorvalue === "supervisor"){
				this.refs["supemail" + idx].value = "";
				this.refs["supdivision" + idx].value = "";
				this.refs["suproom" + idx].value = "";
				this.refs["supname" + idx].value = "";
			  }else{
				this.refs["proxyemail" + idx].value = "";
				this.refs["proxyadname" + idx].value = "";
				
			  }
			return;
		}
		}
		}
		catch (err) {
		  this.setState({
		  isAuthenticated: false,
		  suser: {},
		  error: normalizeError(err)
		  });
		}
	  }

	  /* Proxy Details*/

	  _handleKeyDownproxy = idx => e => {
		
		if (e.key === 'Enter' || e.key === 'Tab') {
			var cvalue = 0;
			this.state.proxyrows.map(function(item, i){
				if(i != idx){
					if(cvalue == 0){
						if(e.target.value.toLowerCase() == item["Email"]){
							Swal.fire({
								icon: 'error',
								title: 'Duplicate value not allowed!'
							  })
							e.target.value = "";
							cvalue = 1;
							return;
						}
					}
				}
			  })
			  if(cvalue == 0){
				  this.setState({
					supervisorvalue : "proxy",
					proxysave: true
				  })

				if(this.props.graphToken != "browser")
				  this.singleUsergraph(e.target.value, idx);
			  	else
				  this.singleUser(e.target.value, idx); 

			  }
		}
	  }

	  submitproxy () {
		if(this.validateproxy()){		
		if(this.state.proxyrows){
			this.setState({
				proxysave: false,
				supervisorvalue : "proxy"				
			})

		 let nrows = [...this.state.proxyrows]
		 var proxyemail = '';

		 if(nrows.length > 0){
				 for(var i=0; i < nrows.length; i++)
				 {
					proxyemail = this.state.proxyrows[i].Email; 
					if(this.props.graphToken != "browser")
				  		this.singleUsergraph(proxyemail, i);
			  		else 
						this.singleUser(proxyemail, i);
				 }
				 								 
					this.setState({
						btnstatus: false,
						proxystatus: false,
						seen: true
					})
				Swal.fire({
					icon: 'success',
					title: 'Data saved successfully'
				})
	   
		 }
		 else{
			 Swal.fire({
				 icon: 'error',
				 title: 'please enter proxy email!'
			   })
			 return;
		 }
		}
	}
	}

	handlecloseproxy = () => {
	 this.setState({
	  seen: true
	 });
	};

	  togglePopProxy = pidx => e => {

		var tapiToken = this.state.tapiToken;
		const taskOptions = {
				method: 'GET',
				headers: { 
							'Content-Type': 'application/json',
							'Authorization': 'Bearer ' + tapiToken, 
						}
		};
		if(this.state.proxystatus === false){
			if(this.state.rows){
				var supervisoremail = this.state.rows[pidx].supemail;
				var supervisorname = this.state.rows[pidx].supname;
				this.setState({
					proxysupervisoremail: supervisoremail,
					proxysupervisorName: supervisorname,
					proxyrows: [],
					btnAddProxy: false,
					btnSaveProxy: true,
					errorsproxy: {}
				})
				
				if(supervisoremail != null || supervisoremail != ''){
				fetch(this.props.apiURL + 'Daily/getProxyDetailsPop/' + supervisoremail + "/", taskOptions)
				.then(function(res) {
					return res.json();
				}).then((json)=> {
					if(json.supervisorDetails.length > 0){
												
						this.setState({
							proxyrows: json.supervisorDetails
						})
					}
					this.setState({
						seen: !this.state.seen
					})
				});
				}
			}
		 }
	   }

	  handleAddRowproxy = (idx) => {
		const item = {
			chkproxy: ""
		};
		this.setState({
			proxyrows: [...this.state.proxyrows, item],
			btnAddProxy: true,
			btnSaveProxy: false
		});
											   
	  }

	  async handleRemoveRowproxy() {
		try{
			const apiToken = await getApiToken();
			if(apiToken){
				if(this.state.checkedallproxy === true || this.state.checkedproxy === true){
				Swal.fire({
					title: 'Are you sure?',
					text: "You won't be able to revert this!",
					icon: 'warning',
					showCancelButton: true,
					confirmButtonColor: '#3085d6',
					cancelButtonColor: '#d33',
					confirmButtonText: 'Yes, delete it!'
				}).then((result) => {
					if (result.isConfirmed) {
						if(this.state.checkedallproxy == false) {
							for(var i=0; i < this.state.proxyrows.length; i++){
								if(this.refs["check_Proxy" + i]){

									var proxyid = 0;
									if(this.state.proxyrows[i])
										proxyid  = this.state.proxyrows[i].proxyid;

									var supervisoremail = this.state.proxyrows[i].supervisoremail;
															
								if(this.refs["check_Proxy" + i].checked === true){
																								
									const taskOptions = {
										method: 'PUT',
										headers: { 
													'Content-Type': 'application/json',
													'Authorization': 'Bearer ' + apiToken
												 },
										body: JSON.stringify({ supervisoremail: supervisoremail ,
																proxyid: proxyid
									})
									};
									fetch(this.props.apiURL + 'Daily/deleteProxyDetailsPop', taskOptions)
											.then(function(response) {
												return response.json();
											}).then((data)=> {
												if(data.proxyDetails.length > 0){
												this.getHRdetails();
												}
											});
							}
						}
								this.refs["check_Proxy" + i].checked = false;
							}
						}else {

							for(var i=0; i < this.state.proxyrows.length; i++){
								
								if(this.refs["check_Proxy" + i]){

									var proxyid = 0;
									if(this.state.proxyrows[i])
										proxyid  = this.state.proxyrows[i].proxyid;
									var supervisoremail = this.state.proxyrows[i].supervisoremail;

								if(this.refs["check_Proxy" + i].checked === true){
								
									const taskOptions = {
										method: 'PUT',
										headers: { 
													'Content-Type': 'application/json',
													'Authorization': 'Bearer ' + apiToken
												 },
										body: JSON.stringify({ supervisoremail: supervisoremail ,
											proxyid: proxyid 
									})
									};
									fetch(this.props.apiURL + 'Daily/deleteProxyDetailsPop', taskOptions)
											.then(function(response) {
												return response.json();
			
											}).then((data)=> {
												if(data.proxyDetails.length > 0){
												
												this.getHRdetails();
												}
											});
								
							}
						}
								this.refs["check_Proxy" + i].checked = false;
							}
						}
						this.setState({
							proxyrows: [],
							seen: true,
							btnAddProxy: true,
							btnSaveProxy: false,
							checkedallproxy : false,
							checkedproxy : false
						})	 
						Swal.fire(
							'Deleted!',
							'success'
						)			
					}
				})

				}
		}
		}catch(err){
			throw err;
		}

	  }

	  handleChangeproxy = idx => e => {
							
		const { name, value } = e.target;
		const proxyrows = [...this.state.proxyrows];
		proxyrows[idx] = {
		  [name]: value
		};
		this.setState({
			proxyrows
		});
												
	  };

	  handleCheckboxChangeproxy = idx => e => {
		this.setState({
			checkedallproxy: false,	
			checkedproxy: false
	   })
	
		if (e.target.checked) {
			this.setState({
				checkedproxy: true	
		   })
			e.target.checked = true;
		}
	  }

	  handleCheckAllproxy= e => {
		let proxyrows = [...this.state.proxyrows]	
		this.setState({
			checkedallproxy: false,	
			checkedproxy: false
	   })

		for(var i=0; i < proxyrows.length; i++){
			this.refs["check_Proxy" + i].checked = false;
		  }

		if (e.target.checked) {

		  this.setState ({
			   checkedallproxy: true	
		  })

		  for(var i=0; i < proxyrows.length; i++){
			this.refs["check_Proxy" + i].checked = true;
		  }
		}
	  }

	  validate(){
		let errors = {};
		let isValid = true;

		let nrows = [...this.state.rows]	
				if(nrows.length > 0){
					for(var i=0; i < nrows.length; i++){
						if(this.state.rows[i]){
							if(!this.state.rows[i].supemail){
								isValid = false;
								errors[i] = "Please enter your email Address.";
							}

							if(this.state.rows[i].supemail){
								var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
							if (!pattern.test(this.state.rows[i].supemail)) {
								isValid = false;
								errors[i] = "Please enter valid email address.";
							}
							}
						}
					}
				}
	
		this.setState({
		  errors: errors
		});
	
		return isValid;
	}

	validateproxy(){
		let errorsproxy = {};
		let isValidproxy = true;

		let proxyrows = [...this.state.proxyrows]	
				if(proxyrows.length > 0){
					for(var i=0; i < proxyrows.length; i++){
						if(this.state.proxyrows[i]){
							if(!this.state.proxyrows[i].Email){
								isValidproxy = false;
								errorsproxy[i] = "Please enter your email Address.";
							}

							if(this.state.proxyrows[i].Email){
								var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
							if (!pattern.test(this.state.proxyrows[i].Email)) {
								isValidproxy = false;
								errorsproxy[i] = "Please enter valid email address.";
							}
							}
						}
					}
				}
	
		this.setState({
			errorsproxy: errorsproxy
		});
	
		return isValidproxy;
	}

	componentDidMount() {
		this.getCheckHRAdmin();
		this.getHRdetails();
	}

  render() {
	return  (
		<div className="manage-supervisor-wrapper">
			{/* <div>{textdiv}</div> */}
		<div className="header">
<h4>MANAGE DIVISIONS ADMINS</h4>
</div>
<div className={this.state.hrHide ? 'hrhidden' : 'authorization-wrapper'}>
	  <p className="phrhidden"><i class="fa fa-cog" aria-hidden="true"></i>
You need HR Admin authorization</p>
  </div>
  <div className={this.state.hrAuthorization ? 'hrhidden' : ''}>
  <div className="container">
	  <div className="middle-section manage-teams-wrapper attendance-planner-wrapper"> 
		  <div className="d-table-wrapper">
				   <div className="d-table-body">
				  <div className="table-buttons-wrapper"> 
					  <button onClick={this.handleAddRow} className="btn btn-default pull-left" disabled={this.state.btnAddDelStatus}><i className="fa fa-plus" aria-hidden="true"></i>&nbsp;Add</button>
					  <button onClick={this.handleRemoveRow} className="btn btn-default" disabled={this.state.btnAddDelStatus}><i className="fa fa-trash" aria-hidden="true"></i>&nbsp;Delete</button>
				   </div>
					<div className="table-responsive">
				   <table>
				   <thead>
							<tr>
							<th className="hrhidden"> # </th>
							  <th width="30"> 
								  <input 
						type="checkbox" 
						name="chkAll"
					  onChange={this.handleCheckAll.bind(this)}
					  checked={this.state.checkedall}  
				  />
							  </th>
							  <th width="230">Email</th>
							  <th width="">Name</th>
							  <th width="">Division</th>
							  <th width="">Room or Area</th>
							  <th width="">Proxy</th>
							  <th width="120">Action</th>
							  </tr>
							</thead>
							<tbody>
							{this.state.rows.map((item, idx) => (
							<tr id="addr0" key={idx}>
							<td className="hrhidden">{idx}</td>
				<td>
				  <input 
					type="checkbox" 
					name="chkRow"
					onChange={this.handleCheckboxChange(idx)}
					ref={"check_row" + idx}
					id={idx}
					/>
				</td>
									  <td>
										   

												   <input
					type="text"
					name="supemail"
					ref={"supemail" + idx}
					value={this.state.rows[idx].supemail}
					onChange={this.handleChangeemail(idx)}
					onKeyDown={this._handleKeyDown(idx)}
					className="form-control"
					disabled={!this.state.btnstatus}
				  />
<div className="text-danger">{this.state.errors[idx]}</div>
										 
									  </td>
									  <td>
										  {/* <label for="supname">{this.state.namerows[idx].supname}</label> */}
										  <input
					type="text"
					name="supname"
					ref={"supname" + idx}
					value={this.state.namerows[idx].supname}
					className="form-control inputtextbox"
					readOnly = {true}
				  />
										  </td>
			  <td>
			  {/* <label for="supdivision" ref={"supdivision" + idx}>{this.state.hrows[idx].supdivision}</label> */}
				  <input
					type="text"
					name="supdivision"
					ref={"supdivision" + idx}
					value={this.state.hrows[idx].supdivision}
					className="form-control inputtextbox"
					readOnly = {true}
				  />
			  </td>
			  <td>
				  {/* <label for="suproom" ref={"suproom" + idx}>{this.state.prows[idx].suproom}</label> */}
				  <input
					type="text"
					name="suproom"
					ref={"suproom" + idx}
					value={this.state.prows[idx].suproom}
					className="form-control inputtextbox"
					readOnly = {true}
				  />

<input
							 type="hidden"
							 name="SupervisorID" 
							 value={this.state.rows[idx].SupervisorID}
							 ref={"Supervisor_ID" + idx}
							 //onChange={this.handleChangeTask(idx)}
							 className="form-control"
						   />
			  </td>
			  <td>
			   {/* {this.state.newlineProxyName.map(col => <div >{col.proxyname} <br></br></div>)}  */}
				   {/* <div>{this.state.rows[idx].proxyname}</div>  */}

				   {/* <div>{this.state.newlineProxyName}</div>  */}


				   <div dangerouslySetInnerHTML = {{__html: this.state.rows[idx].proxyname}} /> 

				  {/* <div>testing <br></br>testing2</div> */}
				
				  
				{/* <input
					type="text"
					name="proxyname"
					value={this.state.rows[idx].proxyname}
					//onChange={this.handleChangeproxy(idx)}
					//onKeyDown={this._handleKeyDown(idx)}
					//className="form-control"
					className="form-control inputtextbox"
					readOnly={true}
				  /> */}


			</td>
			<td>
											<a href="#" className={this.state.proxystatus ? 'disabled' : ''} onClick={this.togglePopProxy(idx)}> Manage Proxy</a>
											</td>
								   </tr>
								   ))}
								</tbody>
						  </table>
						  </div>
			  </div>
			   <div className="d-table-bottom "> 
			  <span  className="info-message fl"><i class="fa fa-info-circle" aria-hidden="true"></i> Enter email and press Tab to locate Supervisor</span>
						   <div className="fr">
					  <button
						  className="primary-btn btn" 
						  value="Save"
						  onClick={this.submit}
						  disabled={!this.state.btnstatus}
						  > 
						  Save
					  </button>
					  <button
						  className="primary-btn btn" 
						  value="Cancel"
						  disabled={!this.state.btnstatus}
						onClick={this.cancel}
						  > 
						  Cancel
					  </button>
			 </div>
					 
					  <div className="clear"></div>
	  
									
			  </div>

			  <div id="myModal"  className={this.state.seen ? 'hide' : 'modal'}>

			  {/* <div id="myModal"  className='modal'> */}
							 
							 <div class="modal-content modal-large">
								 <span class="close" onClick={this.handlecloseproxy}>&times;</span>
							 <div>
								 <div className="modal-header"> <h4>Manage Proxy for {this.state.proxysupervisorName}</h4> </div>
								 <div className="modal-body">
							 
									 <div className="gray-bg">
										 <div className="table-top-menu">
											 <button onClick={this.handleAddRowproxy} className="btn btn-default pull-left" disabled={this.state.btnAddProxy}><i className="fa fa-plus" aria-hidden="true"></i>&nbsp;Add</button>
											 <button onClick={this.handleRemoveRowproxy} className="btn btn-default" disabled={this.state.btnAddProxy}><i className="fa fa-trash" aria-hidden="true"></i>&nbsp;Delete</button>
									
										 </div>
 
										   <div className="table-responsive">
											 <table
				 className="table table-bordered table-hover"
				 id="tab_logic"
			   >
				 <thead>
				   <tr>
				   <th className="hrhidden"> # </th>
					 <th width="30">
						  <input 
							   type="checkbox" 
							   name="chkAllproxy"
							 onChange={this.handleCheckAllproxy.bind(this)}
							 checked={this.state.checkedallproxy}  
						 />
					 </th>
					  <th>Email </th>
					 <th>Name</th>
					 {/*<th>ID</th>*/}
					  {/*<th>Division</th>*/}
					 {/*<th>Room</th>*/}
					 {/* <th>ProxyID</th> */}
				 
						
				   
					 {/* <th width="100"> ProxyEmail </th> */}
				   </tr>
				 </thead>
				 <tbody>
				   {this.state.proxyrows.map((item, idx) => (
					 <tr id="addr0" key={idx}>
					   <td className="hrhidden">{idx}</td>
					   <td>
						 <input 
						   type="checkbox" 
						   name="chkproxy"
						   onChange={this.handleCheckboxChangeproxy(idx)}
						   ref={"check_Proxy" + idx}
						   id={idx}
						   />
					   </td>
					   <td>
						 <input
						   type="text"
						   name="Email" 
						   disabled={this.state.btnSaveProxy}
						   ref={"proxyemail" + idx}
						   value={this.state.proxyrows[idx].Email}
						   onChange={this.handleChangeproxy(idx)}
						   onKeyDown={this._handleKeyDownproxy(idx)}
						   className="form-control"
						 />

<div className="text-danger">{this.state.errorsproxy[idx]}</div>
					   </td>
					   <td>
					   
						   {/* <div>ref={"hrname" + idx}</div> */}
						 <input
						   type="text"
						   name="proxyadname" 
						   ref={"proxyadname" + idx}
						   value={this.state.proxyrows[idx].EmployeeName}
						   className="form-control inputtextbox"
						   readOnly={true}
						 />
					   </td>
			
					   {/* <input
							 type="text"
							 name="proxyid" 
							 value={this.state.proxyrows[idx].proxyid}
							 ref={"proxy_id" + idx}
							 //onChange={this.handleChangeTask(idx)}
							 className="form-control"
						/> */}
			 
					   {/* <td>
						 <input
						   type="text"
						   name="hours"
						   value={this.state.hrows[idx].hours}
						   onChange={this.handleChangeHour(idx)}
						   className="form-control"
						 />
					   </td> */}
					 </tr>
				   ))}
				 </tbody>
			   </table>
										   </div>	
									 </div>
								 </div>
								 <div className="modal-footer">
								 						 <span className="info-message fl"><i class="fa fa-info-circle" aria-hidden="true"></i> Enter email and press Tab to locate Proxy Member</span>
										 <div className="d-table-bottom fr"> 
									 <button	className="primary-btn btn"
									 value="Saveproxy"
									 disabled={this.state.btnSaveProxy}
									 onClick={this.submitproxy}> 
									 Save
									 </button>
									 <button
						  className="primary-btn btn" 
						  value="Cancel"
						  disabled={this.state.btnSaveProxy}
						onClick={this.cancelproxy}
						  > 
						  Cancel
					  </button>
									  {/* <button className="default-btn btn" onClick={this.handlecloseproxy}>Close</button>  */}
									 </div>
									   <div className="clear"></div>
								 </div>
						 
							 </div>
					  
 
						 </div>
						  
						 </div>
			  {/* <div className="psave"><p> {this.state.pSave}</p></div> */}
		  </div>
	  
	  </div>


</div>
</div>
</div>
);
}
}

export default ManageSupervisor