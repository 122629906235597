import React, { Component } from 'react';
import '../Branding/Common.css';
import 'font-awesome/css/font-awesome.min.css';
//import WayneRancourt from '../Branding/WayneRancourt.jpg'
import { UserAgentApplication } from 'msal';
import { config } from '../AD_config/Config.js';
import { normalizeError, getUserProfile, getSingleProfile, getMSteamsGraphApiSingleUser } from '../AD_config/MSUtils.js';
import Swal from 'sweetalert2';
import calendericon from '../Branding/calendar-icon.svg';
import labelicon from '../Branding/label-icon.svg';
import { getApiToken } from '../AD_config/MSUtils.js';

var startDate = new Date();
var dtStart = startDate.toISOString().substr(0,10);

class AttendancePlanner extends Component {
	
	constructor(props) {
		super(props);
		this.singleUser = this.singleUser.bind(this);
		this.singleUsergraph = this.singleUsergraph.bind(this);
		this.submit = this.submit.bind(this);
		this.handleCheckAll = this.handleCheckAll.bind(this);
		this.handleChangeAttendance1 = this.handleChangeAttendance1.bind(this);
		this.handleChangeAttendance2 = this.handleChangeAttendance2.bind(this);
		this.handleChangeAttendance3 = this.handleChangeAttendance3.bind(this);
		this.handleChangeAttendance4 = this.handleChangeAttendance4.bind(this);
		this.handleChangeAttendance5 = this.handleChangeAttendance5.bind(this);
		this.calcDate = this.calcDate.bind(this);
		this.show = this.show.bind(this);
		this.handleChangedivision = this.handleChangedivision.bind(this);
		this.handleChangeroom = this.handleChangeroom.bind(this);
		this.handleChangename = this.handleChangename.bind(this);
		this.cancel = this.cancel.bind(this);
		this.handleRemoveRow = this.handleRemoveRow.bind(this);

		this.state = {
			pSave: '',
			columns: [],
			rows: [],
			attendanceValues: [],
			checkedBoxes: [],
			adEmail: '',
			adRoom: '',
			adDivision: '',
			postRes: '',
			hrHide: false,
			hrAuthorization: true,
			supemail: '',
			checkedall: false,
			weekstartdate : dtStart,
			weekenddate: '',
			showChild : true,
			errors: {}
		};
	}

	async getfnApiToken() {
		const apiToken = await getApiToken();
		if(apiToken){
			this.getAttendance(apiToken);
		}
	}

	async submit() {
	try{
		const apiToken = await getApiToken();
		if(apiToken){
			var stremail = this.props.Email;
			if(this.props.Email == null || this.props.Email == '')
			stremail = this.props.userPrincipalName;

			if(this.validate()){
					let nrows = [...this.state.rows]	
					if(nrows.length > 0){
						for(var i=0; i < nrows.length; i++){
							if(this.state.rows[i]){
								this.state.errors[i] = "";
								if(!this.state.rows[i].EmployeeName){
									Swal.fire({
										icon: 'error',
										title: 'Please Enter email and press Tab to locate Team Member!'
									})
									return;
								}
							}
						}
					}

					if(nrows.length > 0){
						//console.log("planner", this.state.rows);
						for(var i=0; i < nrows.length; i++){
							var Day1 = 0;
							var Day2 = 0;
							var Day3 = 0;
							var Day4 = 0;
							var Day5 = 0;
							var divisionName = '';
							var RoomName = '';
							var SupType = '';

						if(this.state.rows[i]){

							if(this.state.rows[i].EmployeeName){

							if(this.state.rows[i].DivisionName)
								divisionName = this.state.rows[i].DivisionName;

							if(this.state.rows[i].RoomName)
								RoomName = this.state.rows[i].RoomName;

							if(this.state.rows[i].Day1){
								Day1 = this.state.rows[i].Day1;
							}

							if(this.state.rows[i].Day2){
								Day2 = this.state.rows[i].Day2;
								
							}

							if(this.state.rows[i].Day3){
								Day3 = this.state.rows[i].Day3;
							}

							if(this.state.rows[i].Day4){
								Day4 = this.state.rows[i].Day4;
							}

							if(this.state.rows[i].Day5){
								Day5 = this.state.rows[i].Day5;
							}

							if(this.state.rows[i].SupType){
								SupType = this.state.rows[i].SupType;
							}
						
							const taskOptions = {
								method: 'PUT',
								headers: { 
											'Content-Type': 'application/json',
											'Authorization': 'Bearer ' + apiToken 
										 },
								body: JSON.stringify({ employeeName: this.state.rows[i].EmployeeName,
														email: this.state.rows[i].Email.toLowerCase(),
														date: this.state.weekstartdate,
														divisionName: divisionName,//this.state.rows[i].DivisionName,
														roomName: RoomName,//this.state.rows[i].RoomName,
														attendanceStatus1: Day1,
														attendanceStatus2: Day2,
														attendanceStatus3: Day3,
														attendanceStatus4: Day4,
														attendanceStatus5: Day5,
														updatedby: stremail,
														SupType: SupType
							})
							};
							await fetch(this.props.apiURL + 'Daily/addWeeklyPlanner', taskOptions)
									.then(function(response) {
										return response.json();
										
									}).then((data)=> {
											this.setState({
												postRes: "1"
											})
									});	
		
						}
						if(this.state.postRes == "1"){
							Swal.fire({
								icon: 'success',
								title: 'Data saved successfully'
							})
						}
					}
				}
					}else{
						Swal.fire({
							icon: 'error',
							title: 'Please enter employee details!'
						})
						return;
					}
				}
			}
		}catch(err){
			throw err;
		}
	}

	handleChangeAttendance1= idx => e => {
		const { name, value } = e.target;
		const rows = [...this.state.rows];
			rows[idx].Day1= value;
		this.setState({
		  rows
		});
	  }

	  handleChangeAttendance2= idx => e => {
		const { name, value } = e.target;
		const rows = [...this.state.rows];
			rows[idx].Day2= value;
		this.setState({
		  rows
		});
	  }

	  handleChangeAttendance3= idx => e => {
		const { name, value } = e.target;
		const rows = [...this.state.rows];
			rows[idx].Day3= value;
		this.setState({
		  rows
		});
	  }

	  handleChangeAttendance4= idx => e => {
		const { name, value } = e.target;
		const rows = [...this.state.rows];
			rows[idx].Day4= value;
		this.setState({
		  rows
		});
	  }

	  handleChangeAttendance5= idx => e => {
		const { name, value } = e.target;
		const rows = [...this.state.rows];
			rows[idx].Day5= value;
		this.setState({
		  rows
		});
	  }

	async getAttendance(apitoken) {
	if(apitoken){
			const taskOptions = {
				method: 'GET',
				headers: { 
							'Content-Type': 'application/json',
							'Authorization': 'Bearer ' + apitoken, 
						}
			};
			await fetch(this.props.apiURL + 'Attendance/AttendanceSupervisor', taskOptions)
			.then(function(res) {
				return res.json();
			}).then((json)=> {
				this.setState({
					attendanceValues: json.roomResults
				})
			});				  
		}
	}

	calcDate(sdate){
		var startDate = new Date(sdate);
		var EndDate = new Date(sdate);
		var EndDate1 = new Date(sdate);
		var EndDate2 = new Date(sdate);
		var EndDate3 = new Date(sdate);
		var EndDate4 = new Date(sdate);
		var EndDate5 = new Date(sdate);

		EndDate.setDate(EndDate.getDate() + 4);
		
		var dtStart = startDate.toISOString().substr(0,10);
		var dtEnd = EndDate.toISOString().substr(0,10);

		var day1 = new Date(EndDate1.setDate(startDate.getDate() + 0)).getDate();
		var month1 = EndDate1.toLocaleString('default', { month: 'long' });
		
		var day2 = new Date(EndDate2.setDate(startDate.getDate() + 1)).getDate();
		var month2 = EndDate2.toLocaleString('default', { month: 'long' });

		var day3 = new Date(EndDate3.setDate(startDate.getDate() + 2)).getDate();
		var month3 = EndDate3.toLocaleString('default', { month: 'long' });

		var day4 = new Date(EndDate4.setDate(startDate.getDate() + 3)).getDate();
		var month4 = EndDate4.toLocaleString('default', { month: 'long' });

		var day5= new Date(EndDate5.setDate(startDate.getDate() + 4)).getDate();
		var month5 = EndDate5.toLocaleString('default', { month: 'long' });

		this.state.columns = [
			{ heading: month1 + ' ' +  day1 + ' (Monday)'},
			{ heading: month2 + ' ' +  day2 + ' (Tuesday)'}, 
			{ heading: month3 + ' ' +  day3 + ' (Wednesday)'}, 
			{ heading: month4 + ' ' +  day4 + ' (Thursday)'},
			{ heading: month5 + ' ' +  day5 + ' (Friday)'},
		]
		
		this.setState({
		   weekstartdate: dtStart,
		  weekenddate: dtEnd 
	   },
	   	() => this.show()
	   )  	   
	}

	handlechangeweek = e => {
		var selectedDate = e.target.value + 'T00:00:00';
		var dt = new Date(selectedDate);
		var currentWeekDay = dt.getDay();
		var lessDays = currentWeekDay == 0 ? 6 : currentWeekDay - 1;
		var wkStart = new Date(new Date(dt).setDate(dt.getDate()- lessDays));
		this.calcDate(wkStart);
	}

	_handleKeyDown = idx => e => {
		if (e.key === 'Enter' || e.key === 'Tab') {
			var cvalue = 0;
			this.state.rows.map(function(item, i){
				if(i != idx){
					if(cvalue == 0){
						if(e.target.value.toLowerCase() == item["Email"]){
							Swal.fire({
								icon: 'error',
								title: 'Duplicate value not allowed!'
							  })
							e.target.value = "";
							cvalue = 1;
							return;
						}
					}
				}
			  })
			if(cvalue == 0) {
			//alert(this.props.graphToken);
			if(this.props.graphToken != "browser")
				this.singleUsergraph(e.target.value, idx);
			else
				this.singleUser(e.target.value, idx);
			}
		}
	  }

	  handleChangeemail = idx => e => {
		const { name, value } = e.target;
		const rows = [...this.state.rows];
		rows[idx] = {
		  [name]: value.toLowerCase()
		};
		this.setState({
		  rows
		});
	  };

	  handleChangeemailspace = (idx) => {
		const rows = [...this.state.rows];
		rows[idx] = {
		  "Email": " "
		};
		this.setState({
		  rows
		});
	  };

	  
async show(){

	const apiToken = await getApiToken();
	if(apiToken){
		this.setState({
			rows: [],
			errors: {}
		})
		var currentDate = this.state.weekstartdate;
		var stremail = this.props.Email;
			if(this.props.Email == null || this.props.Email == '')
				stremail = this.props.userPrincipalName;
		
		const taskOptions = {
			method: 'GET',
			headers: { 
						'Content-Type': 'application/json',
						'Authorization': 'Bearer ' + apiToken, 
					}
		};

		await fetch(this.props.apiURL + 'Daily/getSupervisorWeeklyPlanner/' + stremail + "/" + currentDate, taskOptions)
			.then(function(res) {
				return res.json();
			}).then((json)=> {
				if(json.attendanceDetails){
					
				if(json.attendanceDetails.length > 0){
						if(json.attendanceDetails[0].super === 'NA'){
							this.setState({
								hrHide: false,
								hrAuthorization: true
							})
						}else{
						this.setState({
							hrHide: true,
							hrAuthorization: false,
							rows: json.attendanceDetails
						})
					}
				}
				else
				{
					this.setState({
						hrHide: true,
						hrAuthorization: false,
						rows: []
					})
				}
			}		
			});
	}
}

handleAddRow = (idx) => {
	const item = {
	  chkRow: ""
	};
	this.setState({
	  rows: [...this.state.rows, item],
	});
  }

  async handleRemoveRow() {
	try{
		const apiToken = await getApiToken();
		if(apiToken){
			var stremail = this.props.Email;
					if(this.props.Email == null || this.props.Email == '')
						stremail = this.props.userPrincipalName;

			if(this.state.checkedall === true || this.state.checked === true){

			Swal.fire({
				title: 'Are you sure?',
				text: "You won't be able to revert this!",
				icon: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Yes, delete it!'
			}).then((result) => {
				if (result.isConfirmed) {
					if(this.state.checkedall == false) {
						for(var i=0; i < this.state.rows.length; i++){

							if(this.refs["check_row" + i]){
							if(this.refs["check_row" + i].checked === true){
								if(this.state.rows[i].Email){
								
									var Email = this.state.rows[i].Email;
									var SupType = this.state.rows[i].SupType;
								const taskOptions = {
									method: 'PUT',
									headers: { 
												'Content-Type': 'application/json',
												'Authorization': 'Bearer ' + apiToken 
											},
									body: JSON.stringify({ email: this.state.rows[i].Email,
										updatedby: stremail,
										SupType: SupType

								})
								};
								fetch(this.props.apiURL + 'Daily/deleteWeeklyPlanner', taskOptions)
										.then(function(response) {
											return response.json();
										}).then((data)=> {
											this.show()
										});
							}		 
						}
					}
							this.refs["check_row" + i].checked = false;
						}
					}else {

						for(var i=0; i < this.state.rows.length; i++){
							if(this.refs["check_row" + i]){
							if(this.refs["check_row" + i].checked === true){
								if(this.state.rows[i].Email){
								var Email = this.state.rows[i].Email;
								var SupType = this.state.rows[i].SupType;
								const taskOptions = {
									method: 'PUT',
									headers: { 
												'Content-Type': 'application/json',
												'Authorization': 'Bearer ' + apiToken 
											 },
									body: JSON.stringify({ email: Email,
										updatedby: stremail,
										SupType: SupType
								})
								};
								fetch(this.props.apiURL + 'Daily/deleteWeeklyPlanner', taskOptions)
										.then(function(response) {
											return response.json();
										}).then((data)=> {
											this.show()
										});
							}
						}
					}
							this.refs["check_row" + i].checked = false;
						}
					}

					this.setState({
						checkedall: false,
						checked: false
					})

					Swal.fire(
						'Deleted!',
						'success'
					)			
				}
			})

			}
	}
	}catch(err){
		throw err;
	}

  }

  handleCheckboxChange = idx => e => {
	this.setState({
		checkedall: false,
		checked: false	
   })

	if (e.target.checked) {
		this.setState({
			checked: true	
	   })
		e.target.checked = true;
	}
			  	  
  }
				 
  handleCheckAll= e => {
   
	let nrows = [...this.state.rows]	
	this.setState({
		checkedall: false,
		checked: false	
   })

	for(var i=0; i < nrows.length; i++){
		this.refs["check_row" + i].checked = false;
	  }

	if (e.target.checked) {

	  this.setState ({
		   checkedall: true	
	  })

	  for(var i=0; i < nrows.length; i++){
		this.refs["check_row" + i].checked = true;
	 
   
	  }
	}
  }

  async singleUsergraph(supemail, idx) {
	try {
	  const suser = await getMSteamsGraphApiSingleUser(this.props.graphToken, supemail);
	  if(suser){
		if(suser.value[0]){
	  this.setState({
	  isAuthenticated: true,
	  suser: {
		sdisplayName: suser.value[0].displayName,
		semail: suser.value[0].mail || suser.value[0].userPrincipalName,
		sid: suser.value[0].id,
		sattrRoom: suser.value[0].onPremisesExtensionAttributes.extensionAttribute4,
		sattrDivision: suser.value[0].onPremisesExtensionAttributes.extensionAttribute5,
	  },
	  error: null
	  });
				
	  //alert(suser.value[0].onPremisesExtensionAttributes.extensionAttribute5);
	  //alert(suser.value[0].onPremisesExtensionAttributes.extensionAttribute4);

		this.state.rows[idx].EmployeeName  = suser.value[0].displayName;
															  
		this.handleChangedivision(idx, suser.value[0].onPremisesExtensionAttributes.extensionAttribute5);
		this.handleChangeroom(idx, suser.value[0].onPremisesExtensionAttributes.extensionAttribute4);
		this.handleChangename(idx, suser.value[0].displayName);
	}else{
		Swal.fire({
			icon: 'error',
			title: 'Email not found, please validate and try again!'
		  })
		  this.handleChangeemailspace(idx);
		  this.refs["Email" + idx].value = "";
		  this.state.rows[idx].EmployeeName = "";
		return;
	}
	}
	}
	catch (err) {
	  this.setState({
	  isAuthenticated: false,
	  suser: {},
	  error: normalizeError(err)
	  });
	}
  }

	  async singleUser(supemail, idx) {
		try {
		  let userAgentApplication = new UserAgentApplication({
			auth: {
			  clientId: config.clientId,
			  redirectUri: window.location.origin, 
			  authority: config.authority,
			  navigateToLoginRequestUrl: false,
			},
			cache: {
			  cacheLocation: "localStorage",
			  storeAuthStateInCookie: true
			}
			});
  
		  const suser = await getSingleProfile(userAgentApplication, config.scopes, supemail);
		  if(suser){
			if(suser.value[0]){
		  this.setState({
		  isAuthenticated: true,
		  suser: {
			sdisplayName: suser.value[0].displayName,
			semail: suser.value[0].mail || suser.value[0].userPrincipalName,
			sid: suser.value[0].id,
			sattrRoom: suser.value[0].onPremisesExtensionAttributes.extensionAttribute4,
			sattrDivision: suser.value[0].onPremisesExtensionAttributes.extensionAttribute5,
		  },
		  error: null
		  });
																					
			this.state.rows[idx].EmployeeName  = suser.value[0].displayName;
																  
			this.handleChangedivision(idx, suser.value[0].onPremisesExtensionAttributes.extensionAttribute5);
			this.handleChangeroom(idx, suser.value[0].onPremisesExtensionAttributes.extensionAttribute4);
			this.handleChangename(idx, suser.value[0].displayName);
		}else{
			Swal.fire({
				icon: 'error',
				title: 'Email not found, please validate and try again!'
			  })
			  this.handleChangeemailspace(idx);
			  this.refs["Email" + idx].value = "";
			  this.state.rows[idx].EmployeeName = "";
			return;
		}
		}
		}
		catch (err) {
		  this.setState({
		  isAuthenticated: false,
		  suser: {},
		  error: normalizeError(err)
		  });
		}
	  }

	  handleChangedivision = (idx, val) => {

		const rows = [...this.state.rows];
		if(val != null || val != "")
			rows[idx].DivisionName = val;
		else
			rows[idx].DivisionName = "";

		this.setState({
			rows
		});
	  };

	  handleChangeroom = (idx, val) => {
		const rows = [...this.state.rows];
		if(val != null || val != "")
			rows[idx].RoomName = val;
		else
			rows[idx].RoomName = "";

		this.setState({
			rows
		});
	  };

	  handleChangename = (idx, val) => {

		const rows = [...this.state.rows];
		if(val != null || val != ""){
			rows[idx].EmployeeName = val;
		}else{
			rows[idx].EmployeeName = "";
		}
		this.setState({
			rows
		});
	  };

	  validate(){
		let errors = {};
		let isValid = true;

		let nrows = [...this.state.rows]	
				if(nrows.length > 0){
					for(var i=0; i < nrows.length; i++){
						if(this.state.rows[i]){
							if(!this.state.rows[i].Email){
								isValid = false;
								errors[i] = "Please enter your email Address.";
							}

							if(this.state.rows[i].Email){
								var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
							if (!pattern.test(this.state.rows[i].Email)) {
								isValid = false;
								errors[i] = "Please enter valid email address.";
							}
							}
						}
					}
				}
	
		this.setState({
		  errors: errors
		});
	
		return isValid;
	}

	cancel(){
		this.show();
	}

	componentDidMount() {	
		this.getfnApiToken();			
		var dt = new Date();
		var currentWeekDay = dt.getDay();
		var lessDays = currentWeekDay == 0 ? 6 : currentWeekDay - 1;
		var wkStart = new Date(new Date(dt).setDate(dt.getDate()- lessDays));
		this.calcDate(wkStart);
		//this.getAttendance();
		this.setState({
			adDisplayName: this.props.displayName
		})
	}

  render() {
    return (
      <div className="attendance-planner-wrapper">
        <div className="header">
          <h4>Attendance Planner</h4>
        </div>
		<div className={this.state.hrHide ? 'hrhidden' : 'authorization-wrapper'}>
			<p className="phrhidden"><i class="fa fa-cog" aria-hidden="true"></i>
You need Supervisor Admin authorization</p>
			  </div>
		<div className={this.state.hrAuthorization ? 'hrhidden' : ''}>
        <div className="container">
			<div className="topsection">
          <div className="form-group">
							<label><img src={labelicon} alt ="Name Label Icon" className="label-icon"/>Supervisor</label>
							<p className="nopadding">{this.state.adDisplayName}</p>
					</div>
					<div className="form-group">
							<label><img src={calendericon} alt ="Calender Icon" className="label-icon"/>Week Of</label>
							<input
                type="date"
                name="weekstartdate"
                value={this.state.weekstartdate}
                onChange={this.handlechangeweek} 
              />					
					</div>
					<div className="form-group w-70">
							<label>&nbsp;&nbsp;</label>	
							<span className="info-message fl"><i class="fa fa-info-circle" aria-hidden="true"></i>
					Calender will be set to Monday as per selection</span>				
					</div>
					 <div className="form-group">
							{/* <label><img src={calendericon} alt ="Calender Icon" className="label-icon"/>Week End Date</label> */}
							<input
                type="hidden"
                name="weekenddate"
                value={this.state.weekenddate}
				readOnly = {true}
              />
					</div>
					<div className="form-group">
						<label>&nbsp;</label>  
					<button
							    className="hrhidden" 
								value="Save"
								onClick={this.show}
								> 
								Show
							</button>
					{/* <button onClick={this.show} className="btn btn-default pull-left">&nbsp;Show</button> */}
					</div>
					<div className="clear"> </div>
			</div>
			
			<div className="middle-section manage-teams-wrapper attendance-planner-wrapper"> 
				<div className="d-table-wrapper">
				<div className="d-table-header">Team Members</div>
					 	<div className="d-table-body">
						<div className="table-buttons-wrapper"> 
							<button onClick={this.handleAddRow} className="btn btn-default pull-left"><i className="fa fa-plus" aria-hidden="true"></i>&nbsp;Add</button>
							<button onClick={this.handleRemoveRow} className="btn btn-default"><i className="fa fa-trash" aria-hidden="true"></i>&nbsp;Delete</button>
						 </div>
<div className="table-responsive">
						 <table id="matrixTable">
						 <thead>
								  <tr>
								  <th className="hrhidden"> # </th>
									<th width="15">
										<input 
						  	type="checkbox" 
						  	name="chkAll"
							onChange={this.handleCheckAll.bind(this)}
							checked={this.state.checkedall}  
						/>
									</th>
									<th width="20%">Email</th>
							  <th>Name</th>
								{this.state.columns.map(col => <th key={`header-${col.heading}`}>{col.heading}</th>)}
									</tr>
								  </thead>
								  <tbody>
								  {this.state.rows.map((item, idx) => (
								  <tr id="addr0" key={idx}>
								  <td className="hrhidden">{idx}</td>
					  <td>
						<input 
						  type="checkbox" 
						  name="chkRow"
						  onChange={this.handleCheckboxChange(idx)}
						  ref={"check_row" + idx}
						  //checked={this.state.chkValue[idx]}
						  //value={this.state.chkValue[idx]}
						  id={idx}
						  //onClick={this.toggleCheckbox(idx)} 
						  />
					  </td>
											
											

													<td>
										   

										   <input
			type="text"
			name="Email"
			ref={"Email" + idx}
			value={this.state.rows[idx].Email}
			onChange={this.handleChangeemail(idx)}
			onKeyDown={this._handleKeyDown(idx)}
			className="form-control"
		  />
		  <div className="text-danger">{this.state.errors[idx]}</div>

								 
							  </td>
							  <td>
								  <label for="EmployeeName">
									  {this.state.rows[idx].EmployeeName}
									  {this.state.rows[idx].SupType == 'P' ? ' (Proxy)': ''}
								  </label>
							  
							  </td>
							  <td>
							<select name="Day1" value={this.state.rows[idx].Day1} onChange={this.handleChangeAttendance1(idx)} >{
                 this.state.attendanceValues.map((attend) => {
                     return <option value={attend.AttendanceStatus}>{attend.AttendanceStatus}</option>
                 })
              }</select>
					
											</td>
					
											<td>
							<select name="Day2" value={this.state.rows[idx].Day2} onChange={this.handleChangeAttendance2(idx)} >{
                 this.state.attendanceValues.map((attend) => {
                     return <option value={attend.AttendanceStatus}>{attend.AttendanceStatus}</option>
                 })
              }</select>
					
											</td>
											<td>
							<select name="Day3" value={this.state.rows[idx].Day3} onChange={this.handleChangeAttendance3(idx)} >{
                 this.state.attendanceValues.map((attend) => {
                     return <option value={attend.AttendanceStatus}>{attend.AttendanceStatus}</option>
                 })
              }</select>
					
											</td>
											<td>
							<select name="Day4" value={this.state.rows[idx].Day4} onChange={this.handleChangeAttendance4(idx)} >{
                 this.state.attendanceValues.map((attend) => {
                     return <option value={attend.AttendanceStatus}>{attend.AttendanceStatus}</option>
                 })
              }</select>
					
											</td>
											<td>
							<select name="Day5" value={this.state.rows[idx].Day5} onChange={this.handleChangeAttendance5(idx)} >{
                 this.state.attendanceValues.map((attend) => {
                     return <option value={attend.AttendanceStatus}>{attend.AttendanceStatus}</option>
                 })
              }</select>
					
											</td>
										 </tr>
										 ))}
									  </tbody>
								</table>
			  </div>
					</div>
					
					<div className="d-table-bottom"> 
																						   
							   
							<div className="fr">
							<button
							    className="primary-btn btn" 
								value="Save"
								onClick={this.submit}> 
								Save
							</button>
							<button
						  className="primary-btn btn" 
						  value="Cancel"
						onClick={this.cancel}
						  > 
						  Cancel
					  </button>
					  </div>
							<div className="clear"></div>		
					</div>
					<div>
					<span className="info-message block"><i class="fa fa-info-circle" aria-hidden="true"></i>
					Enter email and press Tab to locate Team Member	</span>	
					<span className="info-message block"><i class="fa fa-info-circle" aria-hidden="true"></i>
					For Proxy only planner data will be deleted </span>	
					<span className="info-message block"><i class="fa fa-info-circle" aria-hidden="true"></i>
					Proxy - will be displayed for other team members
					</span>				
					</div>
					<div className="psave"><p> {this.state.pSave}</p></div>
	 
				</div>
			
			</div>
	
	
	</div>
	</div>
      </div>
    );
  }
}

export default AttendancePlanner