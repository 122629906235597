import React, { Component } from 'react';
import '../Branding/Common.css';
//import WayneRancourt from '../Branding/WayneRancourt.jpg'
import Swal from 'sweetalert2'
import calendericon from '../Branding/calendar-icon.svg'
import labelicon from '../Branding/label-icon.svg'
import TimeField from 'react-simple-timefield';
import { getApiToken } from '../AD_config/MSUtils.js';

const timeStyle = {
	width: '99%'
  };
 
class Supervisors extends Component {

	constructor(props) {
		super(props);
		this.getfnApiToken = this.getfnApiToken.bind(this);
		this.getEmpdetails = this.getEmpdetails.bind(this);
		this.handleChangeAttendance = this.handleChangeAttendance.bind(this);
		this.submit = this.submit.bind(this);
		this.submitTask = this.submitTask.bind(this);
		this.handleCheckAll = this.handleCheckAll.bind(this);
		this.getSupervisorDetDB = this.getSupervisorDetDB.bind(this);
		this.handleRemoveRow = this.handleRemoveRow.bind(this);
		this.togglePop = this.togglePop.bind(this);

		var today = new Date(),
		date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();

		var view = '';

		var month  = today.getMonth() + 1;
		var day  = today.getDate();
		if(month < 10){
			if(day < 10)
				view = "0" + (today.getMonth() + 1)  + '-0' + today.getDate() + '-' + today.getFullYear();
			else
				view = "0" + (today.getMonth() + 1)  + '-' + today.getDate() + '-' + today.getFullYear();
		}else{
			if(day < 10)
				view = (today.getMonth() + 1)  + '-0' + today.getDate() + '-' + today.getFullYear();
			else
				view =  (today.getMonth() + 1)  + '-' + today.getDate() + '-' + today.getFullYear();
		}

		this.state = {
			currDate: date,
			viewDate: view,//(today.getMonth() + 1)  + '-' + today.getDate() + '-' + today.getFullYear(),
			pSave: '',
			emprows: [],
			rows: [],
			hrows: [],
			attendanceValues: [],
			adDisplayName: '',
			adRoom: '',
			adDivision: '',
			adAttendance: '',
			postRes: '',
			hrHide: false,
			hrAuthorization: true,
			seen: true,
			empidx: '',
			taskemployeename: '',
			savBtnStatus: true,
			employeeEmailTask: '',
			employeeIDTask: 0,
			errorshours: {},
			errorstask: {},
			tapiToken: ''
		};
	}

	async getfnApiToken() {
		const apiToken = await getApiToken();
		if(apiToken){
			this.getAttendance(apiToken);
			this.setState({
				tapiToken: apiToken
			})
		}
	}

	async getSupervisorDetDB() {

		const apiToken = await getApiToken();
		if(apiToken){

			const taskOptions = {
				method: 'GET',
				headers: { 
							'Content-Type': 'application/json',
							'Authorization': 'Bearer ' + apiToken, 
						}
			};

			var currentDate = this.state.currDate;
			var stremail = this.props.Email;
			if(this.props.Email == null || this.props.Email == '')
				stremail = this.props.userPrincipalName;

			await fetch(this.props.apiURL + 'Daily/getSupervisorRoomDetDB/' + stremail + "/" + currentDate + "/", taskOptions)
			.then(function(res) {
				return res.json();
			}).then((json)=> {
				if(json.roomDetails){
				if(json.roomDetails.length > 0){
					this.setState({
						adRoom: json.roomDetails[0].RoomName
					})
				}
			}
			});
		}
	  }

	async getEmpdetails() {
		const apiToken = await getApiToken();
		if(apiToken){
			const taskOptions = {
				method: 'GET',
				headers: { 
							'Content-Type': 'application/json',
							'Authorization': 'Bearer ' + apiToken, 
						}
			};

			var currentDate = this.state.currDate;
			var stremail = this.props.Email;
			
			if(this.props.Email == null || this.props.Email == '')
				stremail = this.props.userPrincipalName;

			await fetch(this.props.apiURL + 'Daily/getEmployeeDetails/'  + stremail + "/" + currentDate, taskOptions)
			.then(function(res) {
				return res.json();
			}).then((json)=> {
				if(json.employeeDetails.length > 0){

					if(json.employeeDetails[0].super === 'NA'){
						this.setState({
							hrHide: false,
							hrAuthorization: true
						})
					}else{
					this.setState({
						hrHide: true,
						hrAuthorization: false,
						emprows: json.employeeDetails
					})
				}
				}else{
					this.setState({
						hrHide: true,
						hrAuthorization: false,
						emprows: json.employeeDetails
					})
				}
			});
	}
	  }

	 togglePop = eidx => e => {
		let rows = [];
		let hrows = [];
		//const apiToken = await getApiToken();
		//if(apiToken){
			var tapiToken = this.state.tapiToken;
			const taskOptions = {
				method: 'GET',
				headers: { 
							'Content-Type': 'application/json',
							'Authorization': 'Bearer ' + tapiToken, 
						}
			};

			if(this.state.emprows[eidx]){
				if(this.state.emprows[eidx].empattend == null || this.state.emprows[eidx].empattend =="" || this.state.emprows[eidx].empattend == "--Select--"){
					Swal.fire({
						icon: 'error',
						title: 'Please select attendance!'
					})
					return;
				}
				var empemail = this.state.emprows[eidx].empemail;
				this.setState({
					employeeEmailTask: this.state.emprows[eidx].empemail,
					employeeIDTask: eidx,
					errorstask: {},
					errorshours: {}
				})
		fetch(this.props.apiURL + 'Daily/getDailyTask/' + empemail + "/" + this.state.currDate, taskOptions)
			.then(function(res) {
				return res.json();
			}).then((json)=> {

				if(json.dailyDetails.length > 0){
					this.setState({ 
						rows: json.dailyDetails,
						hrows: json.dailyDetails  
					})
				}	
			});
		}
			this.setState({
				seen: !this.state.seen,
				empidx: eidx,
				taskemployeename: this.state.emprows[eidx].EmployeeName,
				rows: rows,
				hrows: hrows
			});
		
		//}
	}

async submitTask () {
	try{
		if(this.validatehours()){
			const apiToken = await getApiToken();
			if(apiToken){
				var employeeEmail = this.state.employeeEmailTask;
				var taskdate = this.state.currDate;
				var empRowID = this.state.employeeIDTask;
				var comments = '';

				var stremail = this.props.Email;
				if(this.props.Email == null || this.props.Email == '')
					stremail = this.props.userPrincipalName;	

				let erows = [...this.state.emprows]
				if(erows.length > 0){

				if(this.state.emprows[empRowID].empcomments){
						comments = this.state.emprows[empRowID].empcomments;
				}
				const requestOptions = {
					method: 'PUT',
					headers: { 
								'Content-Type': 'application/json',
								'Authorization': 'Bearer ' + apiToken 
							},
					body: JSON.stringify({ 	
											email: this.state.emprows[empRowID].empemail,
											date: taskdate,
											attendanceStatus: this.state.emprows[empRowID].empattend,
											comments: comments,
											recordedBy: this.state.emprows[empRowID].SupType,//'S',
											updatedby: stremail 
				})
				};

				await fetch(this.props.apiURL + 'Daily/addDailyCheckInSupervisor', requestOptions)
						.then(function(response) {
							return response.json();
							
						}).then((data)=> {

						});

				if(this.state.rows){
					let nrows = [...this.state.rows]
					if(nrows.length > 0){
							for(var i=0; i < nrows.length; i++)
							{
								var taskid = 0;
								var task = '';
								var hours = '';
								var hour = 0;
								var minute = 0;
								if(this.state.rows[i].taskid !== undefined)
									taskid = this.state.rows[i].taskid;
								if(this.state.rows[i].task)
									task = this.state.rows[i].task; 
								
								if(this.state.hrows[i].hours)
									hours = this.state.hrows[i].hours;
								var harr = hours.split(':');
										if(harr){
											if(harr[0])
												hour = harr[0];
											if(harr[1])
												minute = harr[1];
										}

								const taskOptions = {
									method: 'PUT',
									headers: { 
												'Content-Type': 'application/json',
												'Authorization': 'Bearer ' + apiToken 
											},
									body: JSON.stringify({ email: employeeEmail,
															date: taskdate,
															taskName: task,
															hours: hour,
															iscompleted: false,
															minute: minute,
															taskid: taskid
								})
								};
							fetch(this.props.apiURL + 'Daily/Task', taskOptions)
										.then(function(response) {
											return response.json();
											
										}).then((data)=> {
											if(data.dailyDetails.length > 0){
												this.getEmpdetails();
											}
										});	
							}
							this.setState({
								seen: !this.state.seen
							});
							Swal.fire({
								icon: 'success',
								title: 'Data saved successfully'
								})
					}
					else{
						Swal.fire({
							icon: 'error',
							title: 'please add task!'
						})
						return;
					}
				}
				}
		}
	}
	} catch(err) {
		throw err;
	}
}

	   handleclose = () => {
		this.setState({
		 seen: true
		});
	   };

	   async submit() {
		try{
			debugger;
			const apiToken = await getApiToken();
			var countMsg = 0;
			if(apiToken) {
				var stremail = this.props.Email;
				if(this.props.Email == null || this.props.Email == '')
						stremail = this.props.userPrincipalName;
						let totrows = [...this.state.emprows]
					for(var j = 0; j < totrows.length; j++){	
						if(this.state.emprows[j].empattend == null || this.state.emprows[j].empattend =="" || this.state.emprows[j].empattend == "--Select--"){
							
						}
						else{
							countMsg = 1;
						}
					}
				
				if(countMsg === 0) {
					Swal.fire({
						icon: 'error',
						title: 'Please select attendance, Atleast for one team member'
					})
					return;
				}	

				let erows = [...this.state.emprows]
					if(erows.length > 0){
						for(var i=0; i < erows.length; i++){
							
							var comments = '';
							var attendID = '';
							if(this.state.emprows[i].empattend != null && 
								this.state.emprows[i].empattend !="" &&
									this.state.emprows[i].empattend != "--Select--") {
								attendID = this.state.emprows[i].empattend
							}
								if(this.state.emprows[i].empcomments){
									comments = this.state.emprows[i].empcomments;
								}
								const requestOptions = {
									method: 'PUT',
									headers: { 
												'Content-Type': 'application/json',
												'Authorization': 'Bearer ' + apiToken 
											},
									body: JSON.stringify({ 	
															email: this.state.emprows[i].empemail,
															date: this.state.currDate,
															attendanceStatus: attendID,
															comments: comments,
															recordedBy: this.state.emprows[i].SupType,//'S',
															updatedby: stremail 
									})
								};

								await fetch(this.props.apiURL + 'Daily/addDailyCheckInSupervisor', requestOptions)
									.then(function(response) {
										return response.json();
									}).then((data)=> {
								});
				
							}
									Swal.fire({
										icon: 'success',
										title: 'Data saved successfully'
									})
									return;
						}
					}
		} catch(err) {
			throw err;
		}
}

	handleChangeTask = idx => e => {
		const { name, value } = e.target;
		const rows = [...this.state.rows];
		if(rows[idx].taskid === undefined){
			rows[idx] = {
			[name]: value,
			"taskid": 0
			};
		}else{
			rows[idx] = {
				[name]: value,
				"taskid": rows[idx].taskid
			}
		}
		this.setState({
		  rows
		});
	  };

	  handleChangeHour = idx => e => {
		const { name, value } = e.target;
		const hrows = [...this.state.hrows];
		hrows[idx] = {
		  [name]: value
		};
		this.setState({
		  hrows
		});
	  };

	  handleChangeAttendance= idx => e => {
		
		const emprows = [...this.state.emprows];
		emprows[idx].empattend = e.target.value;

		this.setState({
			emprows
		});
	  }

	  handleChangeComments = idx => e => {
		const emprows = [...this.state.emprows];
		emprows[idx].empcomments = e.target.value;

		this.setState({
			emprows
		});
	  }

	handleAddRow = (idx) => {
		const item = {
		  chkRow: "",
		  task: "",
		  hours: ""
		};
		this.setState({
		  rows: [...this.state.rows, item],
		  hrows: [...this.state.hrows, item],
		  savBtnStatus: false
		});
	  }

	  async handleRemoveRow() {
		try{
			let mrows = [...this.state.rows]
			const apiToken = await getApiToken();
			if(apiToken){
					if(this.state.checkedall === true || this.state.checked === true){

						Swal.fire({
							title: 'Are you sure?',
							text: "You won't be able to revert this!",
							icon: 'warning',
							showCancelButton: true,
							confirmButtonColor: '#3085d6',
							cancelButtonColor: '#d33',
							confirmButtonText: 'Yes, delete it!'
						}).then((result) => {
							if (result.isConfirmed) {

					if(this.state.checkedall == false) {
						for(var i=0; i < mrows.length; i++){
							if(this.refs["check_row" + i].checked === true){
								if(this.refs["task_id" + i].value){
								var taskID = this.refs["task_id" + i].value;
								const taskOptions = {
									method: 'PUT',
									headers: { 
												'Content-Type': 'application/json',
												'Authorization': 'Bearer ' + apiToken 
											 },
									body: JSON.stringify({ taskid: taskID
								})
								};
								fetch(this.props.apiURL + 'Daily/deleteDailyTask', taskOptions)
										.then(function(response) {
											return response.json();
											
										}).then((data)=> {
											this.getEmpdetails();
										});
							}
						}
							this.refs["check_row" + i].checked = false;
						}

					}else {

						for(var i=0; i < mrows.length; i++){
							if(this.refs["check_row" + i].checked === true){
								if(this.refs["task_id" + i].value){
								var taskID = this.refs["task_id" + i].value;
								const taskOptions = {
									method: 'PUT',
									headers: { 
												'Content-Type': 'application/json',
												'Authorization': 'Bearer ' + apiToken 
											 },
									body: JSON.stringify({ taskid: taskID
								})
								};
								fetch(this.props.apiURL + 'Daily/deleteDailyTask', taskOptions)
										.then(function(response) {
											return response.json();
											
										}).then((data)=> {
											this.getEmpdetails();
										});
							}
						}
							this.refs["check_row" + i].checked = false;
						}
					}
					this.setState({
						rows: [],
						hrows: [],
						seen: true,
						checkedall: false,
						checked: false
					})
						Swal.fire(
							'Deleted!',
							'success'
							)
					}
				})
				}	
		}
		}catch(err){
			throw err;
		}

	  }

	  handlecloseproxy = () => {
		this.setState({
		 seen: true
		});
	   };

	handleCheckboxChange = idx => e => {
		this.setState({
			checkedall: false,
			checked: false
		})
		if (e.target.checked) {
			this.setState({
				checked: true
			})
			e.target.checked = true;
		}
	  }

	handleCheckAll= e => {
		 
		let nrows = [...this.state.rows]	
		this.setState({
			checkedall: false,
			checked: false
		})
		for(var i=0; i < nrows.length; i++){
			this.refs["check_row" + i].checked = false;
		  }

		if (e.target.checked) {
		  this.setState ({
			   checkedall: true	
		  })

		  for(var i=0; i < nrows.length; i++){
			this.refs["check_row" + i].checked = true;
		  }
		}
	  }


	async getAttendance(apitoken) {
		if(apitoken){
			const taskOptions = {
				method: 'GET',
				headers: { 
							'Content-Type': 'application/json',
							'Authorization': 'Bearer ' + apitoken, 
						}
			};

			await fetch(this.props.apiURL + 'Attendance/AttendanceSupervisor', taskOptions)
			.then(function(res) {
				return res.json();
			}).then((json)=> {
				this.setState({
					attendanceValues: json.roomResults
				})
			});	
		}			  
	  }

	  validatehours(){
		let errorshours = {};
		let errorstask = {};
		let isValidhours = true;

		let taskrows = [...this.state.rows]	
				if(taskrows.length > 0){
					for(var i=0; i < taskrows.length; i++){
						if(this.state.rows[i]){
							if(!this.state.rows[i].task){
								isValidhours = false;
								errorstask[i] = "Task is required!";
							}
						}
					}
				}

		let hoursrows = [...this.state.hrows]	
				if(hoursrows.length > 0){
					for(var i=0; i < hoursrows.length; i++){
						if(this.state.hrows[i]){
							if(!this.state.hrows[i].hours || this.state.hrows[i].hours == "00:00"){
								isValidhours = false;
								errorshours[i] = "Hours is required!";
							}
						}
					}
				}
	
		this.setState({
			errorstask: errorstask,
			errorshours: errorshours
		});
	
		return isValidhours;
	}

	componentDidMount() {
		this.getfnApiToken();
		this.getSupervisorDetDB();
		//this.getAttendance();
		this.getEmpdetails();
		this.setState({
			adDisplayName: this.props.displayName,
			adDivision: this.props.attrDivision,
			adRoom: this.props.attrRoom
		})
	  }

  render() {
    return (
		<div>
        <div className="header">
          <h4>MANAGE TEAM SCHEDULE</h4>
        </div>
		<div className={this.state.hrHide ? 'hrhidden' : 'authorization-wrapper'}>
	
				  <p className="phrhidden"><i class="fa fa-cog" aria-hidden="true"></i>
You need Supervisor Admin authorization</p>
			  </div>
		<div className={this.state.hrAuthorization ? 'hrhidden' : ''}>
        <div className="container">
			<div className="topsection">
          <div className="form-group">
							<label><img src={labelicon} alt ="Name Label Icon" className="label-icon"/>Supervisor</label>
							 <p>{this.state.adDisplayName}</p> 
							{/* <p>Jonathan Parker</p> */}
					</div>
          <div className="form-group">
							<label><img src={calendericon} alt ="Calender Icon" className="label-icon"/>Date</label>
							<p> {this.state.viewDate}</p>
					</div>
          <div className="form-group">
							<label><img src={labelicon} alt ="Name Label Icon" className="label-icon"/>Division</label>
							 <p> {this.state.adDivision}</p>
							{/* <p>Board Services</p> */}
					</div>
		  <div className="form-group">
							<label><img src={labelicon} alt ="Name Label Icon" className="label-icon"/>Room or Area</label>
							<p> {this.state.adRoom}</p>
							{/* <p>Room 383</p> */}
					</div>
          <div className="clear"> </div>
        </div>
			<div className="middle-section manage-teams-wrapper"> 
				<div className="d-table-wrapper">
					<div className="d-table-header">Team Members</div>
					<div className="d-table-body">
						<div className="table-responsive">
						 <table>
						 <thead>
								  <tr>
								  {/* <th className="hrhidden"> # </th> */}
								  <th className="hrhidden"></th>
									<th>Name</th>
									<th>Attendance</th>
									<th>Planned Attendance</th>
									<th>Comments</th>
									<th>Tasks</th>
									<th>Action</th>
								  </tr>
								  </thead>
								  <tbody>
								  {this.state.emprows.map((item, eidx) => (
                    <tr id="empaddr0" key={eidx}>
                      <td className="hrhidden">{eidx}</td>
									
											<td>
											<span>
												{this.state.emprows[eidx].EmployeeName}
												{this.state.emprows[eidx].SupType == 'P' ? ' (Proxy)': ''}
											</span>
											{/* <input
								type="text"
								name="EmployeeName"
								value={this.state.emprows[eidx].EmployeeName}
								//onChange={this.handleChangeEmpName(idx)}
								className="form-control inputtextbox"
								readOnly = {true}
							  /> */}
												
													<input
								type="hidden"
								name="empemail"
								value={this.state.emprows[eidx].empemail}
								//onChange={this.handleChangeEmpName(idx)}
								className="form-control"
								readOnly = {true}
							  />
													
											</td>
											<td>
							<select name="empattend" value={this.state.emprows[eidx].empattend} onChange={this.handleChangeAttendance(eidx)} >{
                 this.state.attendanceValues.map((attend) => {
                     return <option value={attend.AttendanceStatus}>{attend.AttendanceStatus}</option>
                 })
              }</select>
					
											</td>
											<td>
											<span>{this.state.emprows[eidx].PlannedAttendance}</span>
											</td>
											<td>
											<textarea name="empcomments" rows="5" cols="50" onChange={this.handleChangeComments(eidx)} className="form-control">{this.state.emprows[eidx].empcomments}</textarea>
							  
												</td>
											<td>
											{/* <textarea name="emptask" rows="10" cols="50" className="form-control">{this.state.emprows[eidx].emptask}</textarea> */}
													 {/* <input
								type="textarea"
								name="emptask"
								value={this.state.emprows[eidx].emptask}
								
								className="form-control inputtextbox"
							
							  />	 */}
							  {/* <span>{this.state.emprows[eidx].emptask}</span> */}
							  <div dangerouslySetInnerHTML = {{__html: this.state.emprows[eidx].emptask}} /> 
							  {/* <div>{this.state.emprows[eidx].emptask}</div> */}
							  <input
								type="hidden"
								name="emptaskdb"
								value={this.state.emprows[eidx].emptaskdb}
								
								className="form-control"
							  />	 
											</td>
								
											<td>
											<a href="#" onClick={this.togglePop(eidx)}> Manage Tasks</a>
											</td>
										 </tr>	
									
                  ))}									 
								  </tbody>
								</table>
						 </div>
					</div>
					
					<div className="d-table-bottom text-right"> 
					<span className="info-message fl"><i class="fa fa-info-circle" aria-hidden="true"></i>
					Proxy - will be displayed for other team members
					</span>	
					<div className="fr">
							<button	className="primary-btn btn"
							value="Save"
							onClick={this.submit}
							
							> 
							Save
							</button>
							{/* <button className="default-btn btn">Cancel</button> */}
					</div>
					<div className="clear"></div>
					</div>
				 
					<div id="myModal"  className={this.state.seen ? 'hide' : 'modal'}>
							 
							<div class="modal-content modal-large">
							<span class="close" onClick={this.handlecloseproxy}>&times;</span>
							<div>
								<div className="modal-header"> <h4>Manage Tasks for {this.state.taskemployeename}</h4> </div>
								<div className="modal-body">
							
									<div className="gray-bg">
										<div className="table-top-menu">
											<button onClick={this.handleAddRow} className="btn btn-default pull-left"><i className="fa fa-plus" aria-hidden="true"></i>&nbsp;Add</button>
											<button onClick={this.handleRemoveRow} className="btn btn-default"><i className="fa fa-trash" aria-hidden="true"></i>&nbsp;Delete</button>
										</div>

								  		<div className="table-responsive">
											<table
                className="table table-bordered table-hover"
                id="tab_logic"
              >
                <thead>
                  <tr>
				  <th className="hrhidden"> # </th>
                    <th width="30">
						 <input 
						  	type="checkbox" 
						  	name="chkAll"
							onChange={this.handleCheckAll.bind(this)}
							checked={this.state.checkedall}  
						/>
					</th>
                    <th>Tasks </th>
                    <th width="100"> Hours:Minutes </th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.rows.map((item, idx) => (
                    <tr id="addr0" key={idx}>
                      <td className="hrhidden">{idx}</td>
					  <td>
						<input 
						  type="checkbox" 
						  name="chkRow"
						  onChange={this.handleCheckboxChange(idx)}
						  ref={"check_row" + idx}
						  //checked={this.state.chkValue[idx]}
						  //value={this.state.chkValue[idx]}
						  id={idx}
						  //onClick={this.toggleCheckbox(idx)} 
						  />
					  </td>
                      <td>
                        <input
                          type="text"
                          name="task" 
                          value={this.state.rows[idx].task}
                          onChange={this.handleChangeTask(idx)}
                          className="form-control"
                        />
						<input
							 type="hidden"
							 name="taskid" 
							 value={this.state.rows[idx].taskid}
							 ref={"task_id" + idx}
							 //onChange={this.handleChangeTask(idx)}
							 className="form-control"
						   />
						   <div className="text-danger-task">{this.state.errorstask[idx]}</div>
                      </td>
                      <td>
					  <TimeField name="hours" style={timeStyle} className="timefield form-control" value={this.state.hrows[idx].hours} onChange={this.handleChangeHour(idx)} />
                        <input
                          type="hidden"
                          name="hours"
                          value={this.state.hrows[idx].hours}
                          onChange={this.handleChangeHour(idx)}
                          className="form-control"
                        />
						<div className="text-danger-task">{this.state.errorshours[idx]}</div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
			  							</div>	
									</div>
								</div>
								<div className="modal-footer">
								<span className="info-message"><i class="fa fa-info-circle" aria-hidden="true"></i>
					Hours: 00-23 and Minutes: 00-59</span>
										<div className="d-table-bottom text-right"> 
									<button	className="primary-btn btn"
									value="Savetasks"
									onClick={this.submitTask}
									//disabled={this.state.savBtnStatus}
									> 
									Save
									</button>
									{/* <button className="default-btn btn" onClick={this.handleclose}>Close</button> */}
									</div>
								</div>
						
							</div>
					 

						</div>
						 
						</div>


				</div>
			
			</div>
	
	
	</div>
	</div>
      </div>
    );
  }
}

export default Supervisors