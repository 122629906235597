import { getUserDetails, getSingleUser, getMsTeamsGraph, getMsTeamsGraphSingleUser } from './GraphService';
import { config } from '../AD_config/Config.js';

export function normalizeError(error) {
    var normalizedError = {};
    if (typeof (error) === 'string') {
        var errParts = error.split('|');
        normalizedError = errParts.length > 1 ?
            { message: errParts[1], debug: errParts[0] } :
            { message: error };
    } else {
        normalizedError = {
            message: error.message,
            debug: JSON.stringify(error)
        };
    }
    return normalizedError;
}

export async function getUserProfile(userAgentApplication, scopes) {
    try {
        var accessToken = await getAccessToken(userAgentApplication, scopes);

        if (accessToken) {
            return await getUserDetails(accessToken);
        }
        return null;
    }
    catch (err) {
        throw err;
    }
}

export async function getSingleProfile(userAgentApplication, scopes, hremail) {
    try {
        var accessToken = await getAccessToken(userAgentApplication, scopes);

        if (accessToken) {
            return await getSingleUser(accessToken, hremail);
        }
        return null;
    }
    catch (err) {
        throw err;
    }
}

async function getAccessToken(userAgentApplication, scopes) {
    try {
        var silentResult = await userAgentApplication
            .acquireTokenSilent({
                scopes: scopes
            });

        return silentResult.accessToken;
    } catch (err) {
        if (isInteractionRequired(err)) {
            var interactiveResult = await userAgentApplication
                .acquireTokenPopup({
                    scopes: scopes
                });

            return interactiveResult.accessToken;
        } else {
            throw err;
        }
    }
}

function isInteractionRequired(error) {
    if (!error.message || error.message.length <= 0) {
        return false;
    }

    return (
        error.message.indexOf('consent_required') > -1 ||
        error.message.indexOf('interaction_required') > -1 ||
        error.message.indexOf('login_required') > -1 ||
        error.message.indexOf('no_account_in_silent_request') > -1
    );
}

/*** Get Api Token */
export async function getApiToken() {
    try {

        const taskOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            //body: JSON.stringify({ taskid: taskID})
        };

        const tokenVal = fetch(config.apiURL + 'token', taskOptions)
											.then(function(response) {
                                                return response.text()
                                        });
        return tokenVal;                                        
    }
    catch (err) {
        throw err;
    }
}


export async function getMSteamsGraphApi(graphToken) {
    try {
        if (graphToken) {
            return await getMsTeamsGraph(graphToken);
        }
        return null;
    }
    catch (err) {
        throw err;
    }
}

export async function getMSteamsGraphApiSingleUser(graphToken, hremail) {
    try {
        if (graphToken) {
            return await getMsTeamsGraphSingleUser(graphToken, hremail);
        }
        return null;
    }
    catch (err) {
        throw err;
    }
}

/** Get Admin Role */
export async function getHradminRole(email, spname) {
    try {
        const apiToken = await getApiToken();
        var roleVal = 0;
        const taskOptions = {
            method: 'GET',
            headers: { 
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + apiToken, 
                    }
        };

        roleVal = await fetch(config.apiURL + 'Daily/' + spname + '/' + email + "/", taskOptions)
			.then(function(res) {
				return res.json();
			}).then((json)=> {
				if(json.hradminDetails.length > 0){
                    //console.log("json.hradminDetails : " + JSON.stringify(json.hradminDetails[0].hrAdmin))
                    return json.hradminDetails[0].hrAdmin
				}
			});
        //console.log("roleVal : " + roleVal)
        return roleVal;                                        
    }
    catch (err) {
        throw err;
    }
}
